import { DownloadOutlined, LoadingOutlined, UndoOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { Data } from "@antv/s2";
import { Button, Col, Divider, message, Row, Select, Skeleton, Space, Spin, Tag } from "antd";
import generateRangePicker from "antd/es/date-picker/generatePicker/generateRangePicker";
import axios from "axios";
import useAxios from "axios-hooks";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import generateConfig from "rc-picker/lib/generate/dayjs";
import { ValueDate } from "rc-picker/lib/interface.d";
import { RangeValueType } from "rc-picker/lib/PickerInput/RangePicker";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { createEngagementSummary, EngagementSummary, EngagementSummaryDataset } from "./EngagementSummary";
import { GenerateResultSummary } from "./GenerateResultSummary";
import { DatasetsOnTargetBarChart } from "./reportsCharts/DatasetsOnTargetBarChart";
import { OverallPercentagePieChart } from "./reportsCharts/OverallPercentagePieChart";
import { TargetProgressBarChart } from "./reportsCharts/TargetProgressBarChart";
import { ReportsTable } from "./ReportsTable";
import { UserGuide } from "./UserGuide";
import { exportCSVTwo, IDataset, IPublishedEntry, mungedFilteredData } from "../DatasetDetails/PublishedRecordSet";
import CustomHelmet from "../../components/CustomHelmet";
import { GetAllCategories, GetAllCategories_categories } from "../../graphql/__generated__/GetAllCategories";
import { GetAllPublishedRecordSets } from "../../graphql/__generated__/GetAllPublishedRecordSets";
import { GetReportsData, GetReportsData_reportsData_publishedRecordSets } from "../../graphql/__generated__/GetReportsData";
import { GET_ALL_CATEGORIES } from "../../graphql/__queries__/GetAllCategories.gql";
import { useAuth } from "../../hooks/AuthProvider";
import { useQueryWithErrorHandling } from "../../graphql/hooks/useQueryWithErrorHandling";
import { flattenProgressChartData, groupedByCategory, groupedByMonthYearCategory, IChartData } from "../../selectors/ChartData";
import { sortDiversityCategories } from "../../utils/sortDiversityCategories";

dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(timezone);

const RangePicker = generateRangePicker<Dayjs>(generateConfig);


type AdminReportCsvDownloadProps = {
    categories: GetAllCategories_categories[];
    dateRange: DateRange;
    filterState: FilterState;
    isAdmin: boolean;
    loading: boolean;
    tz: string;
    filterPublishedRecords: (
        publishedRecordSets: readonly GetReportsData_reportsData_publishedRecordSets[]
    ) => Promise<GetReportsData_reportsData_publishedRecordSets[]>;
}

export type DateRange = {
    begin: Dayjs;
    end: Dayjs;
}

type FilterSelectOption = {
    label: string;
    value: string;
    key: string;
}

export type FilterState = {
    categories: GetAllCategories_categories[];
    contentTypes: FilterStateItem[];
    departments: FilterStateItem[];
    divisions: FilterStateItem[];
    miscTags: FilterStateItem[];
    platforms: FilterStateItem[];
    programs: FilterStateItem[];
    range: DateRange;
    teams: FilterStateItem[];
}

export type FilterStateItem = {
    id: string;
    name: string;
}

type ReportsTableDataCategory = {
    name: string;
    displayName: string;
    priority: number | null;
}


const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";


const getPresetDateRanges = (): ValueDate<Exclude<RangeValueType<Dayjs>, null>>[] => [
    { 
        label: "Today",
        value: [dayjs().startOf("day"), dayjs().endOf("day")]
    },
    { 
        label: "This Month",
        value: [dayjs().startOf("month"), dayjs().endOf("month")]
    },
    { 
        label: "Last Month",
        value: [dayjs().subtract(1, "months").startOf("month"), dayjs().subtract(1, "months").endOf("month") ]
    },
    {
        label: "This Year",
        value: [dayjs().startOf("year"), dayjs().endOf("year")]
    },
];

const AdminReportCsvDownload = (
    { categories, dateRange, filterState, isAdmin, loading, tz, filterPublishedRecords }: AdminReportCsvDownloadProps
) => {
    const [loadingAdmin, setLoadingAdmin] = useState(false);

    const parsePublicRecordSets = useCallback(
        (prs: readonly GetReportsData_reportsData_publishedRecordSets[] | undefined, timezoneName: string) => {
            return prs?.map(x => ({
                ...x,
                begin: dayjs(x.begin, EXPECTED_DATE_TIME_FORMAT, timezoneName),
                end: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, timezoneName)
            }));
        }, []
    );

    const createAdminReportCsvFilename = () => {
        const { categories, contentTypes, departments, divisions, miscTags, platforms, programs, teams } = filterState;
        const mappedCategories = categories.map(({ displayName }) => ({ name: displayName }));
        return [
            ...mappedCategories, ...contentTypes, ...departments, ...divisions, ...miscTags, ...platforms, ...programs, ...teams
        ].map(x => x.name.toString()).join("_").slice(0, 200);
    };

    const onClickGetAdminReportCsv = () => {
        setLoadingAdmin(true);
        const begin = dateRange.begin.tz("UTC", true).toISOString();
        const end = dateRange.end.tz("UTC", true).toISOString();
        const url = `api/admin_reports_data?begin=${begin}&end=${end}`;
    
        axios.get<GetReportsData>(url).then((e) => {
            if (!e?.data?.reportsData.publishedRecordSets) {
                setLoadingAdmin(false);
                return message.error("There are no published record sets");
            }

            filterPublishedRecords(e?.data?.reportsData.publishedRecordSets).then((filteredRecords) => {
                if (!filteredRecords) {
                    return message.error("There are no published record sets corresponding to the selected filters");
                }

                const parsedFilteredRecords = parsePublicRecordSets(filteredRecords, tz);
                const mungedFilteredRecords = mungedFilteredData(parsedFilteredRecords, null, categories);
                const fileName = createAdminReportCsvFilename();

                setLoadingAdmin(false);
                exportCSVTwo(mungedFilteredRecords, fileName);
            });
        }).catch(() => setLoadingAdmin(false));
    };

    return (
        <>
            {
                isAdmin && 
                <Button
                    disabled={loading}
                    icon={loadingAdmin ? <LoadingOutlined /> : <DownloadOutlined />}
                    onClick={onClickGetAdminReportCsv}
                    shape="circle"
                    type="primary"
                />
            }
        </>
    );
};


export const Reports = () => {
    const { t } = useTranslation();
    const auth = useAuth();
    const isAdmin = auth.isAdmin();

    const endOfLastMonth = dayjs().startOf("month").subtract(1, "millisecond");
    const startOfLastMonth = endOfLastMonth.startOf("month");

    const initialFilterState: FilterState = {
        categories: [],
        contentTypes: [],
        departments: [],
        divisions: [],
        miscTags: [],
        platforms: [],
        programs: [],
        range: { "begin": startOfLastMonth, "end": endOfLastMonth },
        teams: [],
    };
    
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const [dateRange, setDateRange] = useState<DateRange>({ "begin": startOfLastMonth, "end": endOfLastMonth });
    const [engagementSummary, setEngagementSummary] = useState<EngagementSummary | undefined>();
    const [filterState, setFilterState] = useState<FilterState>(initialFilterState);
    const [filteredPublishedRecords, setFilteredPublishedRecords] = useState<GetReportsData_reportsData_publishedRecordSets[]>([]);
    const [filteredOffAirDatasets, setFilteredOffAirDatasets] = useState<IDataset[]>([]);
    const [filteredUnPublishedDatasets, setFilteredUnPublishedDatasets] = useState<IDataset[]>([]);

    const queryCategories = useQueryWithErrorHandling<GetAllCategories>(
        GET_ALL_CATEGORIES, "categories", { fetchPolicy: "network-only" }
    );

    const [{ data, loading: currMonthLoading }] = useAxios<GetReportsData>({
        url: "api/reports",
        method: "post",
        data: {
            first: false,
            range: {
                begin: dateRange.begin.tz("UTC", true).toISOString(),
                end: dateRange.end.tz("UTC", true).toISOString()
            }
        }
    });

    const [{ data: prevMonthData, loading: prevMonthLoading }] = useAxios<GetReportsData>({
        url: "api/reports",
        method: "post",
        data: {
            first: false,
            range: {
                begin: dateRange.begin.tz("UTC", true).subtract(1, "day").startOf("month").toISOString(),
                end: dateRange.begin.tz("UTC", true).subtract(1, "day").endOf("month").toISOString()
            }
        }
    });

    const [{ data: firstData, loading: firstDataLoading }] = useAxios<Record<string, GetAllPublishedRecordSets>>({
        url: "api/reports",
        method: "post",
        data: {
            first: true,
            range: {
                begin: initialFilterState.range.begin.tz("UTC", true).toISOString(),
                end: initialFilterState.range.end.tz("UTC", true).toISOString()
            }
        }
    });

    const filterWorkers = useMemo(() => {
        let optimumNoOfWorkers = window.navigator.hardwareConcurrency ?? 8;
        if (optimumNoOfWorkers % 2 === 1) {
            optimumNoOfWorkers = optimumNoOfWorkers + 1;
        }

        return {
            publishedRecordsFilterWorkers: [...Array(optimumNoOfWorkers / 2)]
                .map(() => new Worker(new URL("./workers/publishedRecordsFilterWorker.js", import.meta.url))),
            datasetFilterWorkers: [...Array(optimumNoOfWorkers / 2)]
                .map(() => new Worker(new URL("./workers/datasetFilterWorkers.js", import.meta.url)))
        };
    }, []);

    useEffect(() => (
        () => Object.values(filterWorkers).forEach(workerType => workerType.forEach(worker => worker.terminate()))
    ), [filterWorkers]);

    const categories = useMemo(() => (
        [...queryCategories.data?.categories || []]
            .filter((category): category is GetAllCategories_categories => category !== null)
            .sort((a, b) => sortDiversityCategories(a.priority, b.priority))
    ), [queryCategories]);

    const contentTypes = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => x.dataset?.program?.contentTypes?.map(y => ({ id: y.id, name: y.name })) ?? [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.contentTypes?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.contentTypes?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const divisions = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => (x.dataset?.program?.department?.division) ? ({ ...x.dataset?.program?.department?.division }) : [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.department?.division ? ({ ...x.dataset.program?.department.division }) : []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.department?.division ? ({ ...x.program?.department.division }) : []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const departments = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => (x.dataset?.program?.department) ? ({ ...x.dataset?.program?.department }) : [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.department ? ({ ...x.dataset.program?.department }) : []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.department ? ({ ...x.program?.department }) : []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const miscTags = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => x.dataset?.program?.tags?.map(y => ({ id: y.id, name: y.name })) || [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.tags?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.tags?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const platforms = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => x.dataset?.program?.platforms?.map(y => ({ id: y.id, name: y.name })) || [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.platforms?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.platforms?.map(y => ({ id: y.id, name: y.name })) || []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const programs = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => (x.dataset?.program) ? ({ ...x.dataset?.program }) : [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program ? ({ ...x.dataset.program }) : []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (x.program) ? ({ ...x.program }) : []))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const teams = useMemo(() => (
        data?.reportsData.publishedRecordSets
            .flatMap(x => (x.dataset?.program?.team) ? ({ ...x.dataset?.program?.team }) : [])
            .concat(data.reportsData.unpublishedDatasets.flatMap(x => (
                x.dataset.program?.team ? ({ ...x.dataset.program?.team }) : []
            )))
            .concat(data.reportsData.offAirDatasets.flatMap(x => (
                x.program?.team ? ({ ...x.program?.team }) : []
            )))
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((map, current) => {
                map.set(current.id, current.name);
                return map;
            }, new Map())
    ), [data]);

    const publishedRecordsFilterResolver = (worker: Worker) => {
        return new Promise<GetReportsData_reportsData_publishedRecordSets[]>(resolve => {
            worker.onmessage = ({ data: { answer } }) => {
                resolve(answer);
            };
        });
    };

    const datasetsFilterResolver = (worker: Worker) => {
        return new Promise<IDataset[]>(resolve => {
            worker.onmessage = ({ data: { answer } }) => {
                resolve(answer);
            };
        });
    };

    const filterPublishedRecords = useCallback(async (publishedRecordSets: readonly GetReportsData_reportsData_publishedRecordSets[]) => {
        const chunkSize = Math.ceil(publishedRecordSets.length / filterWorkers.publishedRecordsFilterWorkers.length);

        const chunks = new Array<GetReportsData_reportsData_publishedRecordSets[]>;
        for (let i = 0; i < publishedRecordSets.length; i += chunkSize) {
            const chunk = publishedRecordSets.slice(i, i + chunkSize);
            chunks.push(chunk);
        }

        const promises = new Array<Promise<GetReportsData_reportsData_publishedRecordSets[]>>();

        filterWorkers.publishedRecordsFilterWorkers.map((w, i) => {
            if (!chunks[i]) {
                return;
            }

            w.postMessage([
                chunks[i],
                filterState.contentTypes,
                filterState.departments,
                filterState.divisions,
                filterState.miscTags,
                filterState.platforms,
                filterState.programs,
                filterState.teams,
            ]);
            promises.push(publishedRecordsFilterResolver(w));
        });

        const results = await Promise.all(promises);
        const fd = new Array<GetReportsData_reportsData_publishedRecordSets>().concat(...results);
        return fd;
    }, [
        filterWorkers,
        filterState.contentTypes,
        filterState.departments,
        filterState.divisions,
        filterState.miscTags,
        filterState.platforms,
        filterState.programs,
        filterState.teams,
    ]);

    const filterDatasets = useCallback(async (datasets: readonly IDataset[]) => {
        const chunkSize = Math.ceil(datasets.length / filterWorkers.datasetFilterWorkers.length);

        const chunks = new Array<IDataset[]>;
        for (let i = 0; i < datasets.length; i += chunkSize) {
            const chunk = datasets.slice(i, i + chunkSize);
            chunks.push(chunk);
        }

        const promises = new Array<Promise<IDataset[]>>();

        filterWorkers.datasetFilterWorkers.map((w, i) => {
            if (!chunks[i]) {
                return;
            }

            w.postMessage([
                chunks[i],
                filterState.contentTypes,
                filterState.departments,
                filterState.divisions,
                filterState.miscTags,
                filterState.platforms,
                filterState.programs,
                filterState.teams,
            ]);
            promises.push(datasetsFilterResolver(w));
        });

        const results = await Promise.all(promises);
        const fd = new Array<IDataset>().concat(...results);
        return fd;
    }, [
        filterWorkers,
        filterState.contentTypes,
        filterState.departments,
        filterState.divisions,
        filterState.miscTags,
        filterState.platforms,
        filterState.programs,
        filterState.teams,
    ]);

    useEffect(() => {
        if (!data?.reportsData.publishedRecordSets ||!data.reportsData.publishedRecordSets.length) {
            setFilteredPublishedRecords([]);
        } else {
            filterPublishedRecords(data?.reportsData.publishedRecordSets).then((fd) => {
                setFilteredPublishedRecords(fd);
            });    
        }

        if (!data?.reportsData.offAirDatasets || !data.reportsData.offAirDatasets.length) {
            setFilteredOffAirDatasets([]);
            setFilteredUnPublishedDatasets([]);
        } else {
            filterDatasets(data?.reportsData.offAirDatasets).then((fd) => {
                setFilteredOffAirDatasets(fd);

                if (!data?.reportsData.unpublishedDatasets || !data.reportsData.unpublishedDatasets.length) {
                    setFilteredUnPublishedDatasets([]);
                } else {
                    filterDatasets(data?.reportsData.unpublishedDatasets.map(x => ({ ...x.dataset, end: x.end })))
                        .then((fd) => {
                            setFilteredUnPublishedDatasets(fd);
                        });    
                }
            });
        }
    }, [
        data,
        filterState.contentTypes,
        filterState.departments,
        filterState.divisions,
        filterState.miscTags,
        filterState.platforms,
        filterState.programs,
        filterState.teams,
        filterPublishedRecords,
        filterDatasets,
    ]);

    const tz = useMemo(() => dayjs.tz.guess(), []);

    const grouping = useMemo(() => (
        filteredPublishedRecords ? groupedByCategory(filteredPublishedRecords, tz) : {}
    ), [filteredPublishedRecords, tz]);

    const ReportsTableData = useMemo(() => {
        const publishedDataRows = Array<Data>();
        const noDataRows = Array<Data>();
        const offAirRows = Array<Data>();
        const cats = (filterState.categories.length > 0 ? filterState.categories : categories)
            .map(({ name, displayName, priority }) => ({ name, displayName, priority }));
        const sortedCats: ReportsTableDataCategory[] = cats.sort((a, b) => (
            sortDiversityCategories(a.priority, b.priority)
        ));

        const datasetsPRSsMap = filteredPublishedRecords.length > 0 
            ? filteredPublishedRecords.reduce((map, x) => {
                if (!map.has(x.datasetId)) {
                    map.set(x.datasetId, []);
                }
                map.get(x.datasetId)?.push(x.end);
                return map;
            }, new Map<string, string[]>())
            : new Map<string, string[]>();

        const unPublishedDatasetsMap = filteredUnPublishedDatasets?.reduce((map, x) => {
            if (x.end) {
                if (!map.has(x.id)) {
                    map.set(x.id, []);
                }
                map.get(x.id)?.push(x.end);
            }
            return map;
        }, new Map<string, string[]>()) ?? new Map<string, string>();

        const rowProto = (x: (IDataset | null | undefined), category: ReportsTableDataCategory, hasMultiple: boolean, end?: string) => {
            const dname = x?.name ?? "Unknown";
            const pname = x?.program?.name ?? dname;
            const endDate = end && hasMultiple ? ` - ${dayjs(end, EXPECTED_DATE_TIME_FORMAT, tz).format("MMM YY")}` : "";
            const cname = `${pname === dname ? dname : pname + " " + dname}${endDate}`;

            return {
                DATASET: cname,
                Department: x?.program?.department?.name ?? "Unknown",
                Category: `${category.priority ?? 0}#${category.displayName.toLocaleUpperCase()}`,
                end: end ? dayjs(end).unix() : 0
            };
        };

        const ReportsTableRow = (
            rows: Array<Data>,
            x: (IDataset | null | undefined),
            category: ReportsTableDataCategory,
            categoryIndex: number,
            targetValue: number | undefined,
            percentage: number,
            isUnPubbed = false,
            isOffAir = false
        ) => {
            // Sometimes data is published, but then at some time after the reporting period is deleted.
            if (!x || datasetsPRSsMap.has(x.id)) {
                return;
            }

            let hasMultiple = false;
            if (isUnPubbed) {
                const datasetUnpubbedPRSs = unPublishedDatasetsMap.get(x.name);
                hasMultiple = (datasetUnpubbedPRSs && datasetUnpubbedPRSs.length > 1) ? true : false;
            }

            rows.push({
                ...rowProto(x, category, hasMultiple, x.end),
                Metric: "Actual",
                "%": percentage,
                offAir: Number(isOffAir),
                unPublished: Number(isUnPubbed),
                weight: percentage
            });

            if (targetValue && categoryIndex !== 0) {
                const targetPercentage = Math.round(targetValue * 100);
                rows.push({
                    ...rowProto(x, category, hasMultiple, x.end),
                    "%": targetPercentage,
                    "Metric": "Target",
                    weight: targetPercentage,
                });
            }
        };

        const reducePRS = (entries: Record<string, IPublishedEntry>): number | null | undefined => {
            if (!Object.values(entries).length) {
                return;
            }

            let inTargetTotal = Object.values(entries).reduce((total, entry) => {
                if (entry.targetMember) {
                    total += entry.percent;
                }
                return total;
            }, 0);

            const ooTargetTotal = Object.values(entries).reduce((total, entry) => {
                if (!entry.targetMember) {
                    total += entry.percent;
                }
                return total;
            }, 0);


            if (inTargetTotal + ooTargetTotal === 0) {
                return null;
            }

            inTargetTotal = Math.round(inTargetTotal);
            return inTargetTotal;
        };

        const getTarget = (prs: GetReportsData_reportsData_publishedRecordSets, category: string): number | undefined => {
            let targetValue = prs.document.targets.find(x => x.category === category)?.target;

            if (targetValue) {
                targetValue = Math.round(targetValue);
            }

            return targetValue;
        };

        const getEntries = (
            prs: GetReportsData_reportsData_publishedRecordSets,
            category: string,
        ): Record<string, IPublishedEntry> | undefined => {
            if (!prs || !("Everyone" in prs.document.record) || !(category in prs.document.record["Everyone"])) {
                return;
            }

            return prs.document.record["Everyone"][category].entries;
        };

        sortedCats.forEach((category, index) => {
            filteredPublishedRecords.length > 0 && filteredPublishedRecords.forEach(prs => {
                const entries = getEntries(prs, category.name);

                let inTargetTotal: number | undefined | null = undefined;

                if (entries) {
                    inTargetTotal = reducePRS(entries);
                }

                const datasetPRSs = datasetsPRSsMap.get(prs.datasetId);
                const hasMultiple = datasetPRSs && datasetPRSs.length > 1 ? true : false;

                const dateRangeMinus1PRS = data?.reportsData.publishedRecordSetsPreviousOne
                    .find(x => x.datasetId === prs.datasetId);

                const selectedDateRangePRS = filteredPublishedRecords
                    .filter(x => x.id !== prs.id && x.datasetId === prs.datasetId && dayjs(x.end) < dayjs(prs.end))
                    .sort((a, b) => dayjs(b.end).unix() - dayjs(a.end).unix())
                    .shift();

                let improved = 0;

                const prevPRS = selectedDateRangePRS ?? dateRangeMinus1PRS;
                if (prevPRS) {
                    const prevEntries = getEntries(prevPRS, category.name);
                    if (prevEntries && inTargetTotal !== undefined && inTargetTotal !== null) {
                        const lastInTargetTotal = reducePRS(prevEntries);
                        if (lastInTargetTotal) {
                            improved = inTargetTotal - (lastInTargetTotal ?? inTargetTotal);
                        }
                    }
                }

                const targetValue = getTarget(prs, category.name);

                const inTargetTotalWeight = () => {
                    switch (inTargetTotal) {
                    case undefined:
                        return -2;
                    case null:
                        return -1;
                    default:
                        return inTargetTotal;
                    }
                };

                if (targetValue) {
                    const exceeded = () => {
                        switch (inTargetTotal) {
                        case undefined:
                            return 0;
                        case null:
                            return 0;
                        default:
                            return Number(inTargetTotal >= targetValue);
                        }
                    };
                    publishedDataRows.push({
                        ...rowProto(prs.dataset, category, hasMultiple, prs.end),
                        "%": inTargetTotalWeight(),
                        Metric: "Actual",
                        exceeded: exceeded(),
                        improved: improved,
                        weight: inTargetTotalWeight()
                    });
                }

                if (targetValue && index !== 0) {
                    publishedDataRows.push({
                        ...rowProto(prs.dataset, category, hasMultiple, prs.end),
                        "%": targetValue,
                        Metric: "Target",
                        weight: targetValue
                    });
                }
            });
        });

        sortedCats.forEach((category, index) => filteredOffAirDatasets?.forEach(x => {
            const targetValue = x.program?.targets?.find(y => y.category.name === category.name)?.target;
            return ReportsTableRow(offAirRows, x, category, index, targetValue, -4, false, true);
        }));

        sortedCats.forEach((category, index) => filteredUnPublishedDatasets?.forEach(x => {
            const targetValue = x.program?.targets?.find(y => y.category.name === category.name)?.target;
            return ReportsTableRow(noDataRows, x, category, index, targetValue, -3, true, false);
        }));

        const sorter = (a: Data, b: Data) => {
            if (!a.DATASET || !b.DATASET) {
                return -1;
            }

            const nameA = String(a.DATASET).split("-")[0];
            const nameB = String(b.DATASET).split("-")[0];
            return nameA.localeCompare(nameB);
        };

        publishedDataRows.sort((a, b) => sorter(a, b));
        noDataRows.sort((a, b) => sorter(a, b));
        offAirRows.sort((a, b) => sorter(a, b));

        return [...publishedDataRows, ...noDataRows, ...offAirRows];
    }, [
        categories,
        filteredPublishedRecords,
        filteredOffAirDatasets,
        filteredUnPublishedDatasets,
        filterState,
        data?.reportsData.publishedRecordSetsPreviousOne,
        tz
    ]);

    const parsedSortedFilteredData = useMemo(() => (
        filteredPublishedRecords.map(x => (
            {
                ...x,
                begin: dayjs(x.begin, EXPECTED_DATE_TIME_FORMAT, tz),
                end: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, tz)
            }
        )).sort((a, b) => a.end.unix() - b.end.unix())
    ), [filteredPublishedRecords, tz]);

    const chartData = useMemo(() => {
        const categoryGrouping = (filterState.categories.length > 0 ? filterState.categories : categories)
            .map(({ name }) => name);
        return groupedByMonthYearCategory(parsedSortedFilteredData, categoryGrouping);
    }, [categories, filterState.categories, parsedSortedFilteredData]);

    const setOfDatasetIdsInLastMonthYearOfDateRange = useMemo(() => {
        if (parsedSortedFilteredData && chartData && dateRange.end) {
            return categories.reduce((dict, category) => {
                const groupedDate = dateRange.end.format("MMMM YYYY");
                if (groupedDate in chartData && category.name in chartData[groupedDate]) {
                    dict[category.name] = chartData[groupedDate][category.name].datasets as Set<string>;
                }
                return dict;
            }, {} as Record<string, Set<string>>);
        }
    }, [categories, parsedSortedFilteredData, dateRange.end, chartData]);

    const firstEntryChartData = useMemo(() => {
        if (firstData) {
            const categoryGrouping = (filterState.categories.length > 0 ? filterState.categories : categories)
                .map(({ name }) => name);
            return Object.entries(firstData).reduce((byCategory, [category, firstRecord]) => {
                const parsedSortedFilteredFirstData = firstRecord.publishedRecordSets
                    .flat()
                    .map(x => ({
                        ...x,
                        begin: dayjs(x.begin, EXPECTED_DATE_TIME_FORMAT, tz),
                        end: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, tz)
                    }))
                    .sort((a, b) => a.end.unix() - b.end.unix());

                byCategory[category] =
                    groupedByMonthYearCategory(
                        parsedSortedFilteredFirstData,
                        categoryGrouping,
                        setOfDatasetIdsInLastMonthYearOfDateRange
                    );

                return byCategory;
            }, {} as Record<string, Record<string, Record<string, IChartData>>>);
        }
    }, [categories, filterState.categories, firstData, setOfDatasetIdsInLastMonthYearOfDateRange, tz]);

    const flattenedProgressChartData = useMemo(() => (
        chartData ? flattenProgressChartData(chartData) : new Array<IChartData>()
    ), [chartData]);

    const progressChartData = useMemo(() => {
        if (firstEntryChartData && flattenedProgressChartData) {
            return Object.entries(firstEntryChartData)
                .reduce((groupedByCategory, [category, first]) => {
                    const firstFlattenedProgressChartData = flattenProgressChartData(first, true)
                        .filter(x => x.category === category);
                    groupedByCategory[category] = firstFlattenedProgressChartData
                        .concat(flattenedProgressChartData
                            .filter(y => y.category === category)
                        );
                    return groupedByCategory;
                }, {} as Record<string, IChartData[]>);
        }
    }, [flattenedProgressChartData, firstEntryChartData]);

    useMemo(() => {
        const published: EngagementSummaryDataset[] = filteredPublishedRecords.map(pr => ({
            datasetId: pr.datasetId,
            teamId: pr.dataset?.program?.team?.id,
            trackedCategories: (pr.dataset?.program?.targets || []).map(target => target.category.name),
        }));
        const unpublished: EngagementSummaryDataset[] = filteredUnPublishedDatasets.map(dataset => ({
            datasetId: dataset.id,
            teamId: dataset.program?.team?.id,
            trackedCategories: (dataset?.program?.targets || []).map(target => target.category.name),
        }));
        const offAir: EngagementSummaryDataset[] = filteredOffAirDatasets.map(dataset => ({
            datasetId: dataset.id,
            teamId: dataset.program?.team?.id,
            trackedCategories: (dataset?.program?.targets || []).map(target => target.category.name),
        }));

        if (published.length > 0 && unpublished.length > 0 && offAir.length > 0) {
            const engagementSummary = createEngagementSummary({ categories, published, unpublished, offAir });
            setEngagementSummary(engagementSummary);    
        }
    }, [categories, filteredPublishedRecords, filteredUnPublishedDatasets, filteredOffAirDatasets]);

    const loading = currMonthLoading || prevMonthLoading;
    const rangePickerDefaultValue = [startOfLastMonth, endOfLastMonth] as RangeValueType<Dayjs>;
    const presetDateRanges = getPresetDateRanges();
    
    return (
        <>
            <CustomHelmet title={t("reports.title")} />
            <Space direction="vertical" size="middle">
                <Row key="title">
                    <Col flex="auto">
                        <PageHeader title={t("reports.title")} subTitle={t("reports.subtitle")}/>
                    </Col>
                    <Col flex="400px">
                        <GenerateResultSummary
                            categories={categories}
                            dateRange={dateRange}
                            filterState={filterState}
                            isAdmin={isAdmin}
                            pageLoading={loading}
                            reportsData={{ currentMonth: data?.reportsData, previousMonth: prevMonthData?.reportsData }}
                            tz={tz}
                        />
                    </Col>
                    <Col flex="150px">
                        <UserGuide isAdmin={isAdmin} />
                    </Col>
                </Row>
                <Row justify="space-between" gutter={[16, 16]} key="dateRange">
                    {/* Reset Filters Button */}
                    <Col span={1}>
                        <Button
                            title="Reset Filters"
                            aria-label="Reset Filters"
                            disabled={loading}
                            icon={<UndoOutlined />}
                            onClick={() => setFilterState(initialFilterState)}
                            shape="circle"
                            type="primary"
                        />
                    </Col>
                    {/* Select Date Range Filter */}
                    <Col span={12} style={{ textAlign: "center" }} >
                        <Space align="baseline">
                            <RangePicker
                                picker="month"
                                format="MMMM YYYY"
                                defaultValue={rangePickerDefaultValue}
                                onCalendarChange={(e, _strings, info) => {
                                    const dateRange = [
                                        e && e[0] ? e[0].startOf("month") : dayjs(0),
                                        e && e[1] ? e[1].endOf("month") : dayjs()
                                    ];

                                    if (info.range === "end" && dateRange[1].diff(dateRange[0], "days") > 366) {
                                        message.warning("Selected timespan may cause performance issues");
                                    }
                                    if (info.range === "end") {
                                        setDateRange(curr => {
                                            return curr.begin === dateRange[0] && curr.end === dateRange[1] 
                                                ? curr 
                                                : ({ "begin": dateRange[0], "end": dateRange[1] });
                                        });
                                    }
                                }}
                                presets={presetDateRanges}
                            />
                            { loading && <Spin /> }
                        </Space>
                        <div style={{ textAlign: "center", margin: "5px" }}>
                            Please select both a start <span style={{ fontWeight: "bold", fontStyle: "italic", color: "red" }}>and end date</span> to update the date range
                        </div>
                    </Col>
                    <Col offset={1} span={1}>
                        <AdminReportCsvDownload
                            categories={categories}
                            dateRange={dateRange}
                            filterState={filterState}
                            isAdmin={isAdmin}
                            loading={loading}
                            tz={tz}
                            filterPublishedRecords={filterPublishedRecords}
                        /> 
                    </Col>
                </Row>
                <Row justify="center" gutter={[16, 16]} key="filters1">
                    {/* Select Divisions Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.divisions")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectDivisions")}
                                options={divisions ? Array.from(divisions, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.divisions.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        divisions: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                            />
                        </Space>
                    </Col>
                    {/* Select Departments Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.departments")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectDepartments")}
                                options={departments ? Array.from(departments, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.departments.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        departments: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                    {/* Select Platforms Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.platforms")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectPlatforms")}
                                options={platforms ? Array.from(platforms, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.platforms.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        platforms: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                    {/* Select Content Types Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.contentTypes")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectContentTypes")}
                                options={contentTypes ? Array.from(contentTypes, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.contentTypes.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        contentTypes: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row justify="center" gutter={[16, 16]} key="filters2">
                    {/* Select Categories Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.categories")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectCategories")}
                                options={categories.map(({ name, displayName }) => ({ label: displayName, value: name, key: name }))}
                                value={filterState.categories.map(({ name }) => name)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        categories: (options as FilterSelectOption[])
                                            .map(({ value }) => categories.find(category => category.name === value))
                                            .filter((category): category is GetAllCategories_categories => !!category)
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={(props: CustomTagProps) => (
                                    <Tag
                                        color={categories.find(category => category.name === props.value)?.inTargetColor || "000000"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                )}
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                    {/* Select Teams Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.teams")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectTeams")}
                                options={teams ? Array.from(teams, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.teams.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        teams: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                    {/* Select Datasets Filter */}
                    <Col span={6}>
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <div>{t("reports.programs")}</div>
                            <Select
                                mode="tags"
                                placeholder={t("reports.selectPrograms")}
                                options={programs ? Array.from(programs, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                value={filterState.programs.map(({ id }) => id)}
                                onChange={(_, options) => (
                                    setFilterState(curr => ({ 
                                        ...curr, 
                                        programs: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                    }))
                                )}
                                style={{ width: "100%" }}
                                allowClear
                                maxTagCount={5}
                                tagRender={
                                    (props: CustomTagProps) => <Tag
                                        color={"cyan"}
                                        closable={props.closable}
                                        onClose={props.onClose}
                                        style={{ marginRight: 3 }}
                                    >
                                        {props.label}
                                    </Tag>
                                }
                                filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                            />
                        </Space>
                    </Col>
                    {/* Select Misc. Tags Filter */}
                    <Col span={6}>
                        {
                            isAdmin && 
                            <Space direction="vertical" style={{ width: "100%" }}>
                                <div>{t("reports.tags")}</div>
                                <Select
                                    mode="tags"
                                    placeholder={t("reports.selectTags")}
                                    options={miscTags ? Array.from(miscTags, ([k, v]) => ({ label: v, value: k, key: k })) : []}
                                    value={filterState.miscTags.map(({ id }) => id)}
                                    onChange={(_, options) => (
                                        setFilterState(curr => ({ 
                                            ...curr, 
                                            miscTags: (options as FilterSelectOption[]).map(({ label, value }) => ({ id: value, name: label }))
                                        }))
                                    )}
                                    style={{ width: "100%" }}
                                    allowClear
                                    maxTagCount={5}
                                    tagRender={
                                        (props: CustomTagProps) => <Tag
                                            color={"cyan"}
                                            closable={props.closable}
                                            onClose={props.onClose}
                                            style={{ marginRight: 3 }}
                                        >
                                            {props.label}
                                        </Tag>
                                    }
                                    filterOption={(input, option) => (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())}
                                />
                            </Space>
                        }
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="center" key="Progress">
                    <Col span={24}>
                        {
                            (filterState.categories.length ? filterState.categories : categories).map((category, i) => (
                                <Row key={i} gutter={[32, 0]} style={{ marginBottom: 32 }}>
                                    <Divider orientation="left"><h2>{category.displayName}</h2></Divider>
                                        
                                    {/* Target Progress */}
                                    <Col span={12}>
                                        <Skeleton
                                            loading={loading || firstDataLoading}
                                            active
                                        >
                                            {
                                                progressChartData && progressChartData[category.name] ? 
                                                    <TargetProgressBarChart
                                                        category={category.name}
                                                        data={progressChartData[category.name]}
                                                        filterState={{ ...filterState, range: dateRange }}
                                                        numberOfDatasets={
                                                            setOfDatasetIdsInLastMonthYearOfDateRange?.[category.name]?.size
                                                                ?? 0
                                                        }
                                                    /> : <>No Data</>
                                            }
                                        </Skeleton>
                                    </Col>

                                    {/* Overall Percentage */}
                                    <Col span={12}>
                                        <Skeleton
                                            loading={loading || firstDataLoading}
                                            active
                                        >
                                            <OverallPercentagePieChart
                                                category={category}
                                                data={grouping}
                                                filterState={{ ...filterState, range: dateRange }}
                                            />
                                        </Skeleton>
                                    </Col>
                                </Row>
                            ))
                        }
                    </Col>
                </Row>
            
                {/* Proportion of Datasets on Target */}
                <DatasetsOnTargetBarChart
                    categories={categories}
                    data={chartData}
                    filterState={{ ...filterState, range: dateRange }}
                    loading={loading || firstDataLoading}
                />

                {/* Reports Table */}
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Divider orientation="left"><h2>Reports Table</h2></Divider>
                        <p>
                            The table displays the published results for each category, across all datasets. Cells with a green background show that the target for that category was exceeded. The up and down arrows show how the dataset compares with its last reporting period.
                        </p>
                    </Col>
                    <Col
                        span={20}
                        id="ReportsTableContainer"
                        style={{ height: "calc(100vh - 48px)" }}
                        ref={tableContainerRef}
                    >
                        {
                            ReportsTableData ? <ReportsTable data={ReportsTableData} parentRef={tableContainerRef} /> : <span>No data</span>
                        }
                    </Col>
                    {/* Engagement Summary */}
                    <Col span={4} style={{ marginTop: 68 }}>
                        <EngagementSummary dateRange={dateRange} engagementSummary={engagementSummary} pageLoading={loading} />
                    </Col>
                </Row>
            </Space>
        </>
    );
};