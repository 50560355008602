import { CloseCircleOutlined, DeleteOutlined, PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/";
import { useQuery } from "@apollo/client/react/hooks";
import { Alert,
    Button,
    Cascader,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    List,
    Popconfirm,
    Radio,
    Row,
    Select,
    Spin,
    Switch,
    Typography
} from "antd";
import { FormListOperation } from "antd/lib/form/FormList";
import dayjs, { Dayjs } from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import CustomHelmet from "../../../components/CustomHelmet";
import { Loading } from "../../../components/Loading/Loading";
import { NewStringInput } from "../../../components/NewStringInput";
import { AdminGetAllCustomColumns } from "../../../graphql/__generated__/AdminGetAllCustomColumns";
import { AdminGetAllDatasets } from "../../../graphql/__generated__/AdminGetAllDatasets";
import { AdminGetAllPersonTypes } from "../../../graphql/__generated__/AdminGetAllPersonTypes";
import { AdminGetAllTeams } from "../../../graphql/__generated__/AdminGetAllTeams";
import { AdminGetContentTypes } from "../../../graphql/__generated__/AdminGetContentTypes";
import { AdminGetDivisions } from "../../../graphql/__generated__/AdminGetDivisions";
import { AdminGetPlatforms } from "../../../graphql/__generated__/AdminGetPlatforms";
import { AdminGetProgram, AdminGetProgramVariables, AdminGetProgram_program_targets } from "../../../graphql/__generated__/AdminGetProgram";
import { AdminGetTags } from "../../../graphql/__generated__/AdminGetTags";
import { GetAllCategories, GetAllCategories_categories } from "../../../graphql/__generated__/GetAllCategories";
import { ReportingPeriodType, UpdateProgramInput } from "../../../graphql/__generated__/globalTypes";
import { ADMIN_GET_ALL_CUSTOM_COLUMNS } from "../../../graphql/__queries__/AdminGetAllCustomColumns.gql";
import { ADMIN_GET_ALL_DATASETS } from "../../../graphql/__queries__/AdminGetAllDatasets.gql";
import { ADMIN_GET_ALL_PERSON_TYPES } from "../../../graphql/__queries__/AdminGetAllPersonTypes.gql";
import { ADMIN_GET_ALL_TEAMS } from "../../../graphql/__queries__/AdminGetAllTeams.gql";
import { ADMIN_GET_CONTENT_TYPES } from "../../../graphql/__queries__/AdminGetContentTypes.gql";
import { ADMIN_GET_DIVISIONS } from "../../../graphql/__queries__/AdminGetDivisions.gql";
import { ADMIN_GET_PLATFORMS } from "../../../graphql/__queries__/AdminGetPlatforms.gql";
import { ADMIN_GET_PROGRAM } from "../../../graphql/__queries__/AdminGetProgram.gql";
import { ADMIN_GET_TAGS } from "../../../graphql/__queries__/AdminGetTags";
import { GET_ALL_CATEGORIES } from "../../../graphql/__queries__/GetAllCategories.gql";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { ProgramUpdateFormValues, ReportingPeriod, Target, TargetTrack, useDeactivate, useRestore, useSave } from "../../../hooks/programHooks";
import { sortDiversityCategories } from "../../../utils/sortDiversityCategories";


dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const { Option } = Select;
const { RangePicker } = DatePicker;


export type EditProgramRouteParams = Readonly<{
    programId: string;
}>;

const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";


const getGroupedTargets = (
    targets: readonly AdminGetProgram_program_targets[]
) => {
    const groupedByCategory = targets
        .reduce((grouped, currTarget) => {
            if (currTarget.category.deleted !== null) {
                return grouped;
            }

            if (!grouped.has(currTarget.category.id)) {
                grouped.set(currTarget.category.id, {
                    id: currTarget.id,
                    category: currTarget.category,
                    target: currTarget.target,
                    tracks: [],
                });
            }
            
            currTarget.tracks
                .flat()
                .sort((a, b) => a.categoryValue.name.localeCompare(b.categoryValue.name))
                .forEach(track => {
                    grouped.get(currTarget.category.id)?.tracks.push(
                        {
                            id: track.id,
                            categoryValue: track.categoryValue,
                            targetMember: track.targetMember,
                        }
                    );
                });

            return grouped;
        }, new Map<string, Target>());

    return Array.from(groupedByCategory.values()).sort((a, b) => b.target - a.target);
};

const onClickAddNewReportingPeriod = (reportingPeriods: ReportingPeriod[], reportingPeriodType: ReportingPeriodType, formOperations: FormListOperation) => {
    const lastDate = reportingPeriods.filter(x => x && x.range).length ? [...reportingPeriods]
        .filter(x => x && x.range)
        .sort((a, b) => b.range[1].unix() - a.range[1].unix())[0].range[1] : null;

    switch (reportingPeriodType) {
    case "monthly": {
        const addArrayOfMonths = (startDate: Dayjs) => {
            const endOfYear = startDate.endOf("year");
            startDate.startOf("year");
            while (startDate.isBefore(endOfYear)) {
                formOperations.add({
                    range: [
                        startDate.startOf("month"),
                        startDate.endOf("month")
                    ],
                    description: `${startDate.format("MMMM")}`,
                    groupKey: `${startDate.format("YYYY")}-months`
                });
                startDate = startDate.add(1, "month").startOf("month");
            }
        };
        const buildFromDate = !reportingPeriods || !reportingPeriods.length || !lastDate ? dayjs() : lastDate;

        return addArrayOfMonths(buildFromDate.add(1, "month").startOf("month"));
    }
    case "quarterly": {
        const addArrayOfQuarters = (startDate: Dayjs) => {
            const endOfYear = startDate.endOf("year");
            startDate.startOf("quarter");

            while (startDate.isBefore(endOfYear)) {
                formOperations.add({
                    range: [
                        startDate.startOf("quarter"),
                        startDate.endOf("quarter")
                    ],
                    description: `Quarter ${startDate.quarter()}`,
                    groupKey: `${startDate.format("YYYY")}-quarters`
                });
                startDate = startDate.add(1, "quarter").startOf("quarter");
            }
        };
        const buildFromDate = !reportingPeriods || !reportingPeriods.length || !lastDate ? dayjs() : lastDate;

        return addArrayOfQuarters(buildFromDate.add(1, "quarter").startOf("quarter"));
    }
    case "annual": {
        const addArrayOfYears = (startDate: Dayjs) => {
            formOperations.add({
                range: [
                    startDate,
                    startDate.add(1, "year").subtract(1, "month").endOf("month")
                ],
                description: `${startDate.format("YYYY")}`
            });

        };
        const buildFromDate = !reportingPeriods || !reportingPeriods.length || !lastDate ? dayjs() : lastDate;

        return addArrayOfYears(buildFromDate.quarter() === 1 ? buildFromDate.month(3).startOf("month") : buildFromDate.add(1, "year").month(3).startOf("month"));
    }
    default: {
        return formOperations.add();
    }
    }
};


export const EditProgram = (): JSX.Element => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { programId } = useParams() as EditProgramRouteParams;
    const [editForm] = Form.useForm<ProgramUpdateFormValues>();
    
    const [showDatasets, setShowDatasets] = useState(false);
    
    const save = useSave();
    const restore = useRestore();
    const deactivate = useDeactivate();

    const programResponse = useQueryWithErrorHandling<AdminGetProgram, AdminGetProgramVariables>(
        ADMIN_GET_PROGRAM, 
        "program", 
        { variables: { id: programId ?? "" }, fetchPolicy: "network-only" }
    );
    const teamsResponse = useQueryWithErrorHandling<AdminGetAllTeams>(
        ADMIN_GET_ALL_TEAMS,
        "teams",
        { fetchPolicy: "network-only" }
    );
    const catsResponse = useQueryWithErrorHandling<GetAllCategories>(
        GET_ALL_CATEGORIES,
        "categories",
        { fetchPolicy: "network-only" }
    );
    const tagsResponse = useQueryWithErrorHandling<AdminGetTags>(ADMIN_GET_TAGS, "tags");
    const divisionsResponse = useQueryWithErrorHandling<AdminGetDivisions>(ADMIN_GET_DIVISIONS, "divisions");
    const platformsResponse = useQueryWithErrorHandling<AdminGetPlatforms>(ADMIN_GET_PLATFORMS, "platforms");
    const contentTypesResponse = useQueryWithErrorHandling<AdminGetContentTypes>(ADMIN_GET_CONTENT_TYPES, "contentTypes");
    const personTypesResponse = useQueryWithErrorHandling<AdminGetAllPersonTypes>(ADMIN_GET_ALL_PERSON_TYPES, "personTypes");
    const customColumnsResponse = useQueryWithErrorHandling<AdminGetAllCustomColumns>(
        ADMIN_GET_ALL_CUSTOM_COLUMNS,
        "customColumns"
    );
    const { data: datasetsQry, loading: allDatasetsLoading } = useQuery<AdminGetAllDatasets>(
        ADMIN_GET_ALL_DATASETS,
        { skip: !showDatasets, variables: { onlyUnassigned: true } }
    );

    if (!programId) {
        return <p>bad route</p>;
    }

    if (teamsResponse.loading || programResponse.loading || catsResponse.loading) {
        return <Loading />;
    }

    const tz = dayjs.tz.guess();
    const inactive = !!programResponse.data?.program.deleted;

    const initialFormValues: ProgramUpdateFormValues = {
        name: programResponse.data!.program.name,
        description: programResponse.data!.program.description,
        divisionAndDepartmentIds: programResponse.data!.program.department 
            ? [programResponse.data!.program.department?.division.id, programResponse.data!.program.department.id] : null,
        teamId: programResponse.data!.program.team?.id,
        platforms: programResponse.data?.program.platforms?.map(x => ({ value: x.id, label: x.name, key: x.id })),
        contentTypes: programResponse.data?.program.contentTypes?.map(x => ({ value: x.id, label: x.name, key: x.id })),
        tags: programResponse.data!.program.tags.map(x => ({ value: x.id, label: x.name, key: x.id })),
        targets: getGroupedTargets(programResponse.data!.program.targets)
            .sort((a, b) => sortDiversityCategories(a.category.priority, b.category.priority)),
        reportingPeriodType: programResponse.data!.program.reportingPeriodType,
        reportingPeriods: (programResponse.data!.program.reportingPeriods || []).map(rp => ({
            ...rp,
            range: [dayjs(rp?.range?.[0], EXPECTED_DATE_TIME_FORMAT, tz), dayjs(rp?.range?.[1], EXPECTED_DATE_TIME_FORMAT, tz)]
        })),
        datasets: programResponse.data!.program.datasets.map((ds) => ({
            ...ds,
            personTypes: ds.personTypes.map(({ personTypeName }) => personTypeName),
            customColumns: ds.customColumns?.map(({ name }) => name),
        })),
    };

    const saveProgram = async (values: ProgramUpdateFormValues) => {
        const commonInput: UpdateProgramInput = { id: programId, reportingPeriodType: values.reportingPeriodType };
        const updateProgramInput: UpdateProgramInput = { 
            ...commonInput,
            name: values.name,
            description: values.description,
            teamId: values.teamId,
            departmentId: values.divisionAndDepartmentIds?.[1],
            platforms: values.platforms?.map(({ label, value }) => ({ id: String(value), name: String(label) })),
            contentTypes: values.contentTypes?.map(({ label, value }) => ({ id: String(value), name: String(label) })),
            tags: values.tags?.map(({ label, value }) => ({ id: String(value), name: String(label) })),
            targets: values.targets.map(({ id, category, target, tracks }) => ({
                id,
                category: { id: category.id },
                target: Number(target),
                tracks: tracks.map(({ id, categoryValue, targetMember }) => (
                    { 
                        id,
                        categoryValue: { 
                            id: categoryValue.id,
                            name: categoryValue.name,
                            category: { id: categoryValue.category.id }
                        },
                        targetMember,
                    }
                )),
            })),
            datasets: values.datasets.map(({ id, name, description, personTypes, customColumns }) => (
                { id, name, description, personTypes, customColumns }
            )),  
        };
        const updateProgramReportingPeriodsInput: UpdateProgramInput = {
            ...commonInput,
            reportingPeriods: values.reportingPeriods.map(({ id, description, range }) => {
                const begin = range[0].set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0);
                const end = range[1].set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999);
                return {
                    id,
                    description,
                    programId,
                    range: [begin.tz("UTC", true).toISOString(), end.tz("UTC", true).toISOString()],
                };
            })
        };
        await save.run(updateProgramInput);
        await save.run(updateProgramReportingPeriodsInput);
    };

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title={programResponse.data?.program.name} subTitle={t("admin.program.edit.title")} />

            {/* Page Header */}
            <PageHeader onBack={() => navigate("/admin/programs")} title={t("admin.program.edit.title")}/>

            {/* Banner */}
            {inactive && (
                <Alert
                    action={
                        <Button
                            danger
                            onClick={() => {
                                restore.run(programId);
                                programResponse.refetch();
                            }}
                            type="primary"
                        >
                            {t("admin.program.edit.form.restore")}
                        </Button>
                    }
                    description={t("admin.program.edit.form.alreadyDeletedInfo")}
                    message={t("admin.program.edit.form.alreadyDeletedTitle")}
                    showIcon
                    style={{ marginBottom: 16 }}
                    type="warning"
                />
            )}

            {/* Errors */}
            {[
                { name: "saveError", error: save.error },
                { name: "deleteError", error: deactivate.error },
                { name: "restoreError", error: restore.error },
            ]
                .filter(({ error }) => !!error)
                .map(({ name, error }) => (
                    <Alert
                        closable
                        description={error!.message}
                        key={name}
                        message={t(`admin.program.edit.form.validation.${name}`)}
                        showIcon
                        style={{ marginBottom: 16 }}
                        type="error"
                    />
                ))}

            {/* Edit Dataset Form */}
            <Form
                form={editForm}
                initialValues={initialFormValues}
                labelCol={{ span: 6 }}
                onFinish={async (values) => await saveProgram(values)}
                scrollToFirstError
                wrapperCol={{ span: 14 }}
            >
                {/* Dataset Group Details */}
                <Form.Item
                    label={t("admin.program.edit.form.name")}
                    name="name"
                    rules={[{ required: true, message: t("admin.program.edit.form.validation.nameRequired") }]}
                >
                    <Input
                        aria-label={t("admin.program.edit.form.name")}
                        aria-required="true"
                        disabled={inactive}
                    />
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.description")}
                    name="description"
                >
                    <Input.TextArea
                        aria-label={t("admin.program.edit.form.description")}
                        disabled={inactive}
                    />
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.team")}
                    name="teamId"
                    rules={[{ required: true, message: t("admin.program.edit.form.validation.teamRequired")}]}
                >
                    <Select<string, { value: string; children: string }>
                        disabled={inactive}
                        filterOption={(input, option) => (
                            option!.children.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                        )}
                        filterSort={(a, b) => (
                            a!.children.toLowerCase().localeCompare(b!.children.toLowerCase())
                        )}
                        showSearch
                    >
                        {
                            teamsResponse.data?.teams.map(team => (
                                <Option key={team.id} value={team.id}>{team.name}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.departmentDivision")}
                    name="divisionAndDepartmentIds"
                >
                    <Cascader
                        fieldNames={{ label: "name", value: "id", children: "departments" }}
                        options={
                            divisionsResponse.data?.divisions
                                .flat()
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map(x => ({
                                    ...x,
                                    departments: x.departments?.flat().sort((a, b) => a.name.localeCompare(b.name))
                                }))
                        }
                        placeholder={t("admin.program.edit.form.selectDepartmentDivision")}
                    />
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.platform")}
                    name="platforms"
                >
                    <Select
                        disabled={inactive}
                        fieldNames={{ label: "name", value: "id" }}
                        labelInValue
                        mode="tags"
                        options={platformsResponse.data?.platforms.flat().sort((a, b) => a.name.localeCompare(b.name))}
                        placeholder={t("admin.program.edit.form.selectPlatform")}
                    />
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.contentType")}
                    name="contentTypes"
                >
                    <Select
                        disabled={inactive}
                        fieldNames={{ label: "name", value: "id" }}
                        labelInValue
                        mode="tags"
                        options={
                            contentTypesResponse.data?.contentTypes.flat().sort((a, b) => a.name.localeCompare(b.name))
                        }
                        placeholder={t("admin.program.edit.form.selectContentType")}
                    />
                </Form.Item>
                <Form.Item
                    label={t("admin.program.edit.form.tags")}
                    name="tags"
                >
                    <Select
                        fieldNames={{ label: "name", value: "id" }}
                        labelInValue
                        mode="tags"
                        options={tagsResponse.data?.tags.flat().sort((a, b) => a.name.localeCompare(b.name))}
                        placeholder={t("admin.program.edit.form.selectTags")}
                        style={{ width: "100%" }}
                    />
                </Form.Item>

                {/* Representation Targets */}
                <Typography.Title level={4} style={{ paddingTop: 48 }}>
                    {t("admin.program.edit.targetTitle")}
                </Typography.Title>
                <Form.List name="targets">
                    {(targetFields, targetOps) => (
                        <>
                            {
                                targetFields.map((targetField) => (
                                    <React.Fragment key={targetField.key}>
                                        <Row>
                                            <Col offset={2} span={20}>
                                                <Divider orientation="left">
                                                    {
                                                        editForm.getFieldValue([
                                                            "targets",
                                                            targetField.name,
                                                            "category",
                                                            "displayName"
                                                        ])

                                                    }
                                                </Divider>
                                            </Col>
                                            <Col span={6} offset={2}>
                                                <Form.Item
                                                    label={t("admin.program.edit.form.targetNumber")}
                                                    labelCol={{ span: 10 }}
                                                    name={[targetField.name, "target"]}
                                                    wrapperCol={{ span: 14 }}
                                                >
                                                    <InputNumber
                                                        addonAfter="%"
                                                        formatter={value => `${Math.round(Number(value ?? 0) * 100)}`}
                                                        parser={value => (Number(value ?? 0) / 100).toFixed(2)}
                                                        step={0.01}
                                                        type="number"
                                                    />
                                                </Form.Item>
                                                <Popconfirm
                                                    cancelText={t("confirm.no")}
                                                    disabled={inactive}
                                                    okText={t("confirm.yes")}
                                                    onConfirm={() => targetOps.remove(targetField.name)}
                                                    title={t("admin.program.edit.form.stopTrackingCategoryConfirm")}
                                                >
                                                    <Button danger disabled={inactive}>
                                                        {t("admin.program.edit.form.stopTrackingCategory")}
                                                    </Button>
                                                </Popconfirm>
                                            </Col>
                                            <Col span={14}>
                                                <Form.List
                                                    name={[targetField.name, "tracks"]}
                                                    rules={[{ validator: async (_, segments: TargetTrack[]) => {
                                                        if (segments.length === 0) {
                                                            throw new Error(
                                                                t("admin.program.edit.form.validation.needSegments")
                                                            );
                                                        }

                                                        const uniqueNames = new Set(segments.map((segment) => (
                                                            segment.categoryValue.name?.toLowerCase().trim()
                                                        )));
                                                        if (uniqueNames.size !== segments.length) {
                                                            throw new Error(
                                                                t("admin.program.edit.form.validation.uniqueTargets")
                                                            );
                                                        }
                                                    }}]}
                                                >
                                                    {(trackFields, trackOps, trackMeta) => (
                                                        <>
                                                            {
                                                                trackMeta.errors && (
                                                                    <Row>
                                                                        <Col offset={2}>
                                                                            <Form.ErrorList errors={trackMeta.errors} />
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            {
                                                                trackFields.map((segmentField) => (
                                                                    <Row justify="end" key={segmentField.key}>
                                                                        <Col span={23}>
                                                                            <Form.Item
                                                                                wrapperCol={{ span: 2 }}
                                                                                labelCol={{ span: 22 }}
                                                                                label={editForm.getFieldValue([
                                                                                    "targets",
                                                                                    targetField.name,
                                                                                    "tracks",
                                                                                    segmentField.name,
                                                                                    "categoryValue",
                                                                                    "name",
                                                                                ])}
                                                                                name={[segmentField.name, "targetMember"]}
                                                                                valuePropName="checked"
                                                                            >
                                                                                <Switch
                                                                                    aria-label={editForm.getFieldValue([
                                                                                        "targets",
                                                                                        targetField.name,
                                                                                        "tracks",
                                                                                        segmentField.name,
                                                                                        "categoryValue",
                                                                                        "name",
                                                                                    ])}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={1}>
                                                                            <Form.Item>
                                                                                <Popconfirm
                                                                                    title={t(
                                                                                        "admin.program.edit.form.confirmStopTrackingSegment"
                                                                                    )}
                                                                                    onConfirm={() =>
                                                                                        trackOps.remove(segmentField.name)
                                                                                    }
                                                                                    okText={t("confirm.yes")}
                                                                                    cancelText={t("confirm.no")}
                                                                                    disabled={inactive}
                                                                                >
                                                                                    <Button
                                                                                        style={{ display: "inline" }}
                                                                                        disabled={inactive}
                                                                                        danger
                                                                                        aria-label={t(
                                                                                            "admin.program.edit.form.stopTrackingSegment",
                                                                                            {
                                                                                                segment: editForm
                                                                                                    .getFieldValue([
                                                                                                        "targets",
                                                                                                        targetField.name,
                                                                                                        "tracks",
                                                                                                        segmentField.name,
                                                                                                        "categoryValue",
                                                                                                        "name",
                                                                                                    ])
                                                                                                    ?.toLowerCase(),
                                                                                            }
                                                                                        )}
                                                                                        icon={<CloseCircleOutlined />}
                                                                                        type="text"
                                                                                    ></Button>
                                                                                </Popconfirm>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                ))}
                                                            <Row justify="end">
                                                                <Col>
                                                                    <NewStringInput
                                                                        disabled={inactive}
                                                                        options={
                                                                            (() => {
                                                                                const target: Target = editForm.getFieldValue([
                                                                                    "targets",
                                                                                    targetField.name,
                                                                                ]);
                                                                                const values = catsResponse.data!.categories
                                                                                    .filter((category): category is GetAllCategories_categories => category !== null)
                                                                                    .find((cat) => cat.id === target.category.id)
                                                                                    ?.categoryValues
                                                                                    .map((cv) => cv.name) || [];
                                                                                return values.filter((value) => !target.tracks
                                                                                    .some((segment) => (
                                                                                        segment.categoryValue.name?.toLowerCase() === value.toLowerCase()
                                                                                    ))
                                                                                );
                                                                            })()
                                                                        }
                                                                        placeholder={t(
                                                                            "admin.program.edit.form.addNewSegment",
                                                                            {
                                                                                category: editForm.getFieldValue([
                                                                                    "targets",
                                                                                    targetField.name,
                                                                                    "category",
                                                                                    "displayName",
                                                                                ]),
                                                                            }
                                                                        )}
                                                                        onAdd={(newSegment) => {
                                                                            if (newSegment) {
                                                                                trackOps.add({
                                                                                    categoryValue: {
                                                                                        name: newSegment, category: {
                                                                                            id: editForm.getFieldValue([
                                                                                                "targets",
                                                                                                targetField.name,
                                                                                                "category",
                                                                                                "id",
                                                                                            ])
                                                                                        }
                                                                                    },
                                                                                    targetMember: true,
                                                                                });
                                                                            }
                                                                        }}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                ))
                            }

                            <Row>
                                <Col offset={2} span={20}>
                                    <Divider
                                        orientation="left"
                                        dashed
                                        style={{ borderColor: "#eee" }}
                                    >
                                        {t("admin.program.edit.form.addNewCategory")}
                                    </Divider>
                                    <Select<string, { value: string; children: string }>
                                        disabled={inactive}
                                        aria-label={t("admin.program.edit.form.addNewCategory")}
                                        value={t("admin.program.edit.form.addCategoryPlaceholder")}
                                        onSelect={(newId: string) => {
                                            const newCategory = catsResponse.data!.categories
                                                .filter((category): category is GetAllCategories_categories => category !== null)
                                                .find((category) => category.id === newId);

                                            if (!newCategory) {
                                                console.error(
                                                    "Invariant: selected an invalid category",
                                                    newId
                                                );
                                                return;
                                            }
                                            targetOps.add({
                                                category: newCategory,
                                                tracks: [],
                                            });
                                        }}
                                    >
                                        {
                                            catsResponse.data!.categories
                                                .flat()
                                                .filter((category): category is GetAllCategories_categories => category !== null)
                                                .sort((a, b) => sortDiversityCategories(a.priority, b.priority))
                                                .filter((category) => (
                                                    !((editForm?.getFieldValue("targets") || []) as Target[])
                                                        .find((target) => target.category.id === category.id)
                                                ))
                                                .map((category) => (
                                                    <Option key={category.id} value={category.id}>
                                                        {category.displayName}
                                                    </Option>
                                                ))
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>

                {/* Datasets */}
                <Typography.Title level={4} style={{ paddingTop: 48 }}>
                    {t("admin.program.edit.datasetTitle")}
                </Typography.Title>
                <Row>
                    <Col offset={2} span={20}>
                        <Form.List name="datasets">
                            {(datasetFields, datasetOps) => (
                                <List>
                                    {
                                        datasetFields.map((datasetField) => (
                                            <List.Item
                                                extra={
                                                    <Popconfirm
                                                        title={t("admin.program.edit.form.confirmRemoveDataset")}
                                                        onConfirm={() => datasetOps.remove(datasetField.key)}
                                                        okText={t("confirm.yes")}
                                                        cancelText={t("confirm.no")}
                                                        disabled={inactive}
                                                    >
                                                        <Button
                                                            icon={<CloseCircleOutlined />}
                                                            aria-label={t(
                                                                "admin.program.edit.form.deleteDataset"
                                                            )}
                                                            disabled={inactive}
                                                            danger
                                                            type="text"
                                                        />
                                                    </Popconfirm>
                                                }
                                                key={datasetField.key}
                                            >
                                                <List.Item.Meta
                                                    title={
                                                        <Form.Item
                                                            label={t("admin.program.edit.form.datasetName")}
                                                            wrapperCol={{ span: 24 }}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: t(
                                                                        "admin.program.edit.form.validation.datasetNameRequired"
                                                                    ),
                                                                },
                                                            ]}
                                                            name={[datasetField.name, "name"]}
                                                        >
                                                            <Input
                                                                aria-label={t(
                                                                    "admin.program.edit.form.datasetName"
                                                                )}
                                                                aria-required="true"
                                                                disabled={inactive}
                                                            />
                                                        </Form.Item>
                                                    }
                                                    description={
                                                        <>
                                                            <Form.Item
                                                                label={t("admin.program.edit.form.personTypes")}
                                                                name={[datasetField.name, "personTypes"]}
                                                            >
                                                                <Select<string, { value: string; children: string }>
                                                                    disabled={inactive}
                                                                    aria-label={t(
                                                                        "admin.program.edit.form.personTypes"
                                                                    )}
                                                                    mode="tags"
                                                                    placeholder={t(
                                                                        "admin.program.edit.form.newPersonTypePrompt"
                                                                    )}
                                                                    filterOption={(input, option) => (
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    )}
                                                                >
                                                                    {personTypesResponse.data?.personTypes.map(
                                                                        ({ personTypeName }) => (
                                                                            <Option
                                                                                key={personTypeName}
                                                                                value={personTypeName}
                                                                            >
                                                                                {personTypeName}
                                                                            </Option>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </Form.Item>

                                                            <Form.Item
                                                                label={t("admin.program.edit.form.datasetDescription")}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t(
                                                                            "admin.program.edit.form.validation.datasetDescriptionRequired"
                                                                        ),
                                                                    },
                                                                ]}
                                                                wrapperCol={{ span: 24 }}
                                                                name={[datasetField.name, "description"]}
                                                            >
                                                                <Input.TextArea
                                                                    aria-required="true"
                                                                    aria-label={t(
                                                                        "admin.program.edit.form.datasetDescription"
                                                                    )}
                                                                    disabled={inactive}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                label={t(
                                                                    "admin.program.edit.form.datasetCustomColumns"
                                                                )}
                                                                wrapperCol={{ span: 24 }}
                                                                name={[datasetField.name, "customColumns"]}
                                                            >
                                                                <Select<string, { value: string; children: string }>
                                                                    disabled={inactive}
                                                                    aria-label={t(
                                                                        "admin.program.edit.form.customColumns"
                                                                    )}
                                                                    mode="tags"
                                                                    placeholder={t(
                                                                        "admin.program.edit.form.newCustomColumnPrompt"
                                                                    )}
                                                                    filterOption={(input, option) => (
                                                                    option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    )}
                                                                >
                                                                    {
                                                                        customColumnsResponse.data?.customColumns
                                                                            .map(({ name, id }) => (
                                                                                <Option key={id} value={name}>
                                                                                    {name}
                                                                                </Option>
                                                                            ))
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </>
                                                    }
                                                />
                                            </List.Item>
                                        ))
                                    }
                                    <List.Item style={{ width: "100%" }} hidden={!showDatasets}>
                                        {
                                            !allDatasetsLoading 
                                                ? <Select<string, { value: string; children: string }>
                                                    showSearch
                                                    filterOption={(input, option) => (
                                                        option!.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                                                    )}
                                                    onChange={(value) => (
                                                        datasetOps.add(datasetsQry?.datasets.find((x) => x.id === value))
                                                    )}
                                                    style={{ width: "100%" }}
                                                    placeholder="Select dataset"
                                                >
                                                    {datasetsQry?.datasets.map((dataset, i) => (
                                                        <option key={i} value={dataset.id}>
                                                            {dataset.name}
                                                        </option>
                                                    ))}
                                                </Select>
                                                : <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        alignContent: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <Spin tip="Loading unassigned datasets" />
                                                </div>
                                        }
                                    </List.Item>
                                    <List.Item>
                                        <Button
                                            disabled={inactive}
                                            aria-label={t(
                                                "admin.program.edit.form.addExisitingDataset"
                                            )}
                                            onClick={() => setShowDatasets((curr) => !curr)}
                                            icon={<PlusCircleOutlined />}
                                        >
                                            {t("admin.program.edit.form.addExistingDataset")}
                                        </Button>
                                        <Button
                                            disabled={inactive}
                                            aria-label={t("admin.program.edit.form.addDataset")}
                                            onClick={() =>
                                                datasetOps.add({ name: "", description: "None" })
                                            }
                                            icon={<PlusCircleOutlined />}
                                        >
                                            {t("admin.program.edit.form.addDataset")}
                                        </Button>
                                    </List.Item>
                                </List>
                            )}
                        </Form.List>
                    </Col>
                </Row>
    
                <Typography.Title level={4} style={{ paddingTop: 48 }}>
                    {t("admin.program.edit.reportingPeriodTitle")}
                </Typography.Title>
                <Row>
                    <Col offset={2} span={20}>
                        <Form.Item name="reportingPeriodType">
                            <Radio.Group>
                                {
                                    ["monthly", "quarterly", "annual", "custom"].map(option => (
                                        <Radio key={option} value={option}>{t(option)}</Radio>
                                    ))
                                }
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col offset={2} span={20}>
                        <Form.Item
                            wrapperCol={{ span: 24 }}
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.reportingPeriods !== currentValues.reportingPeriods
                            }
                        >
                            {
                                ({ getFieldValue }) => Array
                                    .from(new Set((getFieldValue("reportingPeriods") as ReportingPeriod[])
                                        .filter(x => x && x.range)
                                        .flatMap(rp => [rp.range[0].year()])
                                    ))
                                    .map((year, i) =>
                                        <Button
                                            key={i}
                                            aria-label={t("admin.program.edit.form.removeReportingPeriodGroup")}
                                            icon={<CloseCircleOutlined />}
                                            size="large"
                                            type="text"
                                            danger
                                            title={t("admin.program.edit.form.removeReportingPeriodGroup")}
                                            onClick={
                                                () => {
                                                    editForm.setFieldsValue({
                                                        reportingPeriods: (getFieldValue("reportingPeriods") as ReportingPeriod[])
                                                            .filter(x => !x || !x.range || !(x.range[0].year() === year))
                                                    });
                                                }
                                            }
                                        >
                                            {year}
                                        </Button>
                                    )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List
                    name="reportingPeriods"
                    rules={[{ validator: async (_, reportingPeriods: ReportingPeriod[]) => {
                        if (reportingPeriods.length === 0) {
                            return Promise.reject(new Error(
                                t("admin.program.edit.form.validation.needReportingPeriods")
                            ));
                        }
                    }}]}
                >
                    {
                        (rpFields, rpOps, errors) =>
                            <>
                                <Row>
                                    <Col offset={2} span={20}>
                                        <Form.ErrorList errors={errors.errors} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col offset={2} span={20}>
                                        <List>
                                            {
                                                rpFields.map(({ key, name, ...restField }) => (
                                                    <List.Item key={key}>
                                                        <Row gutter={16} justify="center" style={{ width: "100%" }}>
                                                            <Col span={7}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "id"]}
                                                                    noStyle
                                                                >
                                                                    <Input type="hidden" />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    wrapperCol={{ span: 24 }}
                                                                    shouldUpdate={(prevValues, currentValues) =>
                                                                        prevValues.reportingPeriodType !== currentValues.reportingPeriodType
                                                                    }
                                                                >
                                                                    {
                                                                        ({ getFieldValue }) => {
                                                                            switch (getFieldValue("reportingPeriodType")) {
                                                                            default: {
                                                                                return (
                                                                                    <Form.Item
                                                                                        name={[name, "range"]}
                                                                                        rules={[
                                                                                            { required: true, message: "Please enter a date range" },
                                                                                            {
                                                                                                validator: (_, value: [Dayjs, Dayjs]) => {
                                                                                                    const overlappingPeriods = (getFieldValue("reportingPeriods") as ReportingPeriod[])
                                                                                                        .filter(x => x.range && (
                                                                                                            value[0].isBetween(x.range[0], x.range[1]) || value[1].isBetween(x.range[0], x.range[1])
                                                                                                        )
                                                                                                        );
                                                                                                    if (value && overlappingPeriods.length) {
                                                                                                        return Promise.reject(new Error(`${t(
                                                                                                            "admin.program.edit.form.validation.overlappingReportingPeriod"
                                                                                                        )}, ${overlappingPeriods.map(p => p.description)}`
                                                                                                        ));
                                                                                                    }
                                                                                                    else {
                                                                                                        return Promise.resolve();
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        {...restField}
                                                                                    >
                                                                                        <RangePicker
                                                                                            format={date => date.format("D MMM YYYY")}
                                                                                        />
                                                                                    </Form.Item>
                                                                                );
                                                                            }
                                                                            }
                                                                        }
                                                                    }
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={15}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, "description"]}
                                                                    wrapperCol={{ span: 24 }}
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <Input style={{ width: "100%" }} placeholder="Description"></Input>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={2} style={{ textAlign: "right" }}>
                                                                <Button
                                                                    type="text"
                                                                    aria-label={t("admin.program.edit.form.removeReportingPeriod")}
                                                                    icon={<CloseCircleOutlined />}
                                                                    danger
                                                                    title={t("admin.program.edit.form.removeReportingPeriod")}
                                                                    onClick={() => rpOps.remove(name)}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                ))
                                            }
                                        </List>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col offset={2} span={20}>
                                        <Form.Item
                                            wrapperCol={{ span: 24 }}
                                            shouldUpdate={(prevValues, currentValues) => (
                                                prevValues.reportingPeriodType !== currentValues.reportingPeriodType
                                            )}
                                        >
                                            {
                                                ({ getFieldValue }) =>
                                                    <Button
                                                        icon={<PlusCircleOutlined />}
                                                        onClick={() => onClickAddNewReportingPeriod(
                                                            getFieldValue("reportingPeriods"),
                                                            getFieldValue("reportingPeriodType"),
                                                            rpOps
                                                        )}
                                                    >
                                                        {t("admin.program.edit.form.addCustomReportingPeriod")}
                                                    </Button>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                    }
                </Form.List>

                <Row justify="center">
                    <Form.Item style={{ paddingTop: 48 }} wrapperCol={{ span: 24 }}>
                        <Button
                            type="primary"
                            icon={<SaveOutlined />}
                            htmlType="submit"
                            disabled={deactivate.inFlight || inactive}
                            loading={save.inFlight}
                        >
                            {t("admin.program.edit.form.save")}
                        </Button>
                        <Popconfirm
                            title={t("admin.program.edit.form.confirmDelete")}
                            onConfirm={() => {
                                deactivate.run(programId);
                                programResponse.refetch();
                            }}
                            disabled={inactive}
                            okText={t("confirm.yes")}
                            cancelText={t("confirm.no")}
                        >
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                disabled={inactive}
                                loading={deactivate.inFlight}
                            >
                                {t("admin.program.edit.form.delete")}
                            </Button>
                        </Popconfirm>
                    </Form.Item>
                </Row>
            </Form>
        </>
    );
};
