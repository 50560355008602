import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { ApolloQueryResult } from "@apollo/client";
import { Button, Card, Col, ColorPicker, Form, FormInstance, FormListFieldData, FormListOperation, Input, Popconfirm, Row, Select, Space } from "antd";
import { Color } from "antd/es/color-picker";
import { DefaultOptionType } from "antd/es/select";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";

import "./ManageDiversityCategories.css";
import CustomHelmet from "../../../components/CustomHelmet";
import { GetAdminCategories, GetAdminCategories_adminCategories } from "../../../graphql/__generated__/GetAdminCategories";
import { CreateCategoryInput } from "../../../graphql/__generated__/globalTypes";
import { GET_ADMIN_CATEGORIES } from "../../../graphql/__queries__/GetAdminCategories.gql";
import { 
    useCreateDiversityCategory,
    useDeleteDiversityCategory,
    useRestoreDiversityCategory,
    useUpdateDiversityCategory,
} from "../../../hooks/programHooks";
import { useQueryWithErrorHandling } from "../../../graphql/hooks/useQueryWithErrorHandling";
import { sortDiversityCategories } from "../../../utils/sortDiversityCategories";


type BaseButtonProps = CommonComponentProps & {
    isDeleted: boolean;
    isNewCategory: boolean;
}

type CommonComponentProps = {
    field: FormListFieldData;
    form: FormInstance<EditDiversityCategoriesForm>;
    loading: boolean;
    refresh: () => Promise<ApolloQueryResult<GetAdminCategories>>;
}

type DiversityCategory = GetAdminCategories_adminCategories | CreateCategoryInput;

type DiversityCategoryInputProps = CommonComponentProps & {
    categoryPriorities: number[];
    operations: FormListOperation;
};

type EditDiversityCategoriesForm = {
    categories: DiversityCategory[];
};

type SaveButtonProps = BaseButtonProps & {
    colors: {
        inTargetColor: string;
        outTargetColor: string;
    };
}

type DeleteButtonProps = BaseButtonProps & {
    operations: FormListOperation;
}


const useAdminDiversityCategoryQueries = () => {
    const categoriesResponse = useQueryWithErrorHandling<GetAdminCategories>(
        GET_ADMIN_CATEGORIES, "adminCategories", { fetchPolicy: "network-only" }
    );

    const categories = [...(categoriesResponse.data?.adminCategories || [])]
        .filter((category: GetAdminCategories_adminCategories | null): category is GetAdminCategories_adminCategories => category !== null)
        .sort((a, b) => sortDiversityCategories(a.priority, b.priority));

    return {
        loading: categoriesResponse.loading,
        categories,
        refresh: () => categoriesResponse.refetch(),
    };
};


const SaveButton = ({ colors, field, form, isDeleted, isNewCategory, loading, refresh }: SaveButtonProps) => {
    const { name } = field;
    const createDiversityCategory = useCreateDiversityCategory();
    const updateDiversityCategory = useUpdateDiversityCategory();

    const saveCategory = async () => {
        if (!isDeleted) {
            const fieldValue = form.getFieldValue(["categories", name]);
            
            const id = fieldValue?.id as string | undefined;
            const displayName = fieldValue?.displayName as string | undefined;
            const description = fieldValue?.description as string | undefined;
            const priority = fieldValue?.priority as number | undefined;
            
            if (isNewCategory && displayName && description) {
                await createDiversityCategory.run({ displayName, description, ...(priority && { priority }), ...colors });
            } else if (!isNewCategory && id && displayName && description) {
                await updateDiversityCategory.run({ id, displayName, description, ...(priority && { priority }), ...colors });
            }

            refresh();
        }
    };

    const renderPopConfirmDescription = (isNewCategory: boolean) => {
        return (
            isNewCategory ? 
                <div className="pop-confirm-content">
                    <p>Creation is immediate and will affect the whole site. Please note that once a new category has been created, it can only be soft-deleted.</p>
                    <p>Don&apos;t forget to consider the position of this new category in the overall priority order. If you don&apos;t choose a number, then this category will appear after all other categories that have been given a priority.</p>
                </div> :
                <div className="pop-confirm-content">
                    <p>Any change to the category name, description, or priority order will be immediate and will affect the whole site.</p>
                    <p>Please take a note of the previous name, description, or priority if there is a chance this change will need to be reverted.</p>
                </div>
        );
    };

    return (
        <Popconfirm
            title={`Are you sure you want to ${isNewCategory ? "create" : "update"} this category?`}
            description={() => renderPopConfirmDescription(isNewCategory)}
            onConfirm={saveCategory}
            okText="Yes"
            cancelText="No"
        >
            <Button
                title="Save"
                type="primary"
                disabled={isDeleted}
                icon={<SaveOutlined />}
                loading={loading}
            >
                Save
            </Button>
        </Popconfirm>
    );
};

const DeleteButton = ({ field, form, isDeleted, isNewCategory, loading, operations, refresh }: DeleteButtonProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpenChange = (newOpen: boolean) => {
        if (!newOpen) {
            setOpen(newOpen);
            return;
        }

        if (!isDeleted && isNewCategory) {
            operations.remove(name);
        } else {
            setOpen(newOpen);
        }
    };
    
    const { name } = field;
    const deleteDiversityCategory = useDeleteDiversityCategory();
    const deleteCategory = async () => {
        if (!isDeleted && !isNewCategory) {
            setOpen(false);
            const id = form.getFieldValue(["categories", name, "id"]) as string;
            await deleteDiversityCategory.run(id);
            return refresh();
        }
    };

    const renderPopConfirmDescription = () => (
        <div className="pop-confirm-content">
            <p>Deleting this category will be immediate and will affect the whole site.</p>
            <p>Please note that this is a soft-delete and the category can be restored at a later date if required.</p>
        </div>
    );

    return (
        <Popconfirm
            title="Are you sure you want to delete this category?"
            description={renderPopConfirmDescription}
            open={open}
            onOpenChange={handleOpenChange}
            onConfirm={deleteCategory}
            onCancel={() => setOpen(false)}
            okText="Yes"
            cancelText="No"
        >
            <Button
                title="Delete"
                type="primary"
                danger
                disabled={isDeleted}
                icon={<DeleteOutlined />}
                loading={loading}
            >
                Delete
            </Button>
        </Popconfirm>
    );
};

const RestoreButton = ({ field, form, isDeleted, isNewCategory, loading, refresh }: BaseButtonProps) => {
    const { name } = field;
    const restoreDiversityCategory = useRestoreDiversityCategory();

    const restoreCategory = async () => {
        if (isDeleted) {
            const id = form.getFieldValue(["categories", name, "id"]) as string;
            await restoreDiversityCategory.run(id);
            return refresh();
        }
    };

    const renderPopConfirmDescription = () => (
        <div className="pop-confirm-content">
            <p>Restoring this category will be immediate and will affect the whole site.</p>
            <p>Please note that the category can be deleted again at a later date if required.</p>
        </div>
    );

    return (
        <Popconfirm
            title="Are you sure you want to restore this category?"
            description={renderPopConfirmDescription}
            onConfirm={restoreCategory}
            okText="Yes"
            cancelText="No"
        >
            <Button
                title="Restore"
                type="primary"
                disabled={isNewCategory || !isDeleted}
                ghost
                loading={loading}
            >
                Restore
            </Button>
        </Popconfirm>
    );
};

const DiversityCategoryInput = ({ categoryPriorities, field, form, loading, operations, refresh }: DiversityCategoryInputProps) => {
    const { name, ...restField } = field;

    const [inTargetColor, setInTargetColor] = useState<string>(form.getFieldValue(["categories", name, "inTargetColor"]));
    const [outTargetColor, setOutTargetColor] = useState<string>(form.getFieldValue(["categories", name, "outTargetColor"]));

    const isNewCategory = !form.getFieldValue(["categories", name, "id"]);
    const isDeleted = !isNewCategory && form.getFieldValue(["categories", name, "deleted"]) !== null;

    const validateNameLength = async (value: string): Promise<void | Error> => {
        if (value?.length > 30) {
            return Promise.reject(new Error("A name can contain a maximum of 30 characters (including spaces)."));
        }
        return Promise.resolve();
    };

    const priorityOptions: DefaultOptionType[] = [
        ...categoryPriorities.map(priority => ({ label: priority === 0 ? "None" : priority, value: priority })),
    ];

    return (
        <Card className="input-card">
            <Row gutter={[16, 0]}>
                <Col span={16}>
                    <Form.Item
                        {...restField}
                        label="Name"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        name={[name, "displayName"]}
                        rules={[
                            { required: true, message: "A name must be provided for this diversity category."},
                            { validator: (_, value: string) => validateNameLength(value)},
                        ]}
                    >
                        <Input disabled={loading || isDeleted} placeholder="Category Name"/>
                    </Form.Item>
                </Col>
                <Col offset={2} span={4}>
                    <Form.Item
                        {...restField}
                        label="Priority"
                        labelAlign="right"
                        labelCol={{ span: 12 }}
                        name={[name, "priority"]}
                    >
                        <Select
                            disabled={loading || isDeleted}
                            defaultValue={0}
                            options={priorityOptions}
                            placeholder="Select"
                            title="Priority"
                        />
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item
                        {...restField}
                        label="Description"
                        labelAlign="left"
                        labelCol={{ span: 4 }}
                        name={[name, "description"]}
                        rules={[
                            { required: true, message: "A description must be provided for this diversity category."},
                        ]}
                    >
                        <Input.TextArea
                            disabled={loading || isDeleted}
                            maxLength={500}
                            placeholder="Category Description"
                            rows={3}
                            showCount
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Row align="middle" justify="end">
                        <Col span={12} style={{ textAlign: "right", paddingRight: "8px" }}>
                            <span className="required-input">In Target Colour:</span>
                        </Col>
                        <Col span={12}>
                            <ColorPicker
                                disabled={loading || isDeleted}
                                format="hex"
                                onChange={(color: Color) => setInTargetColor(color.toHexString())}
                                showText
                                value={inTargetColor}
                            />
                        </Col>
                    </Row>
                    <Row align="middle" justify="end">
                        <Col span={12} style={{ textAlign: "right", paddingRight: "8px" }}>
                            <span className="required-input">Out of Target Colour:</span>
                        </Col>
                        <Col span={12}>
                            <ColorPicker
                                disabled={loading || isDeleted}
                                format="hex"
                                onChange={(color: Color) => setOutTargetColor(color.toHexString())}
                                showText
                                value={outTargetColor}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[64, 16]} align="middle" justify="center" className="input-card-buttons">
                <Space>
                    <SaveButton
                        colors={{ inTargetColor, outTargetColor }}
                        field={field}
                        form={form}
                        isDeleted={isDeleted}
                        isNewCategory={isNewCategory}
                        loading={loading}
                        refresh={refresh}
                    />
                    <DeleteButton
                        field={field}
                        form={form}
                        isDeleted={isDeleted}
                        isNewCategory={isNewCategory}
                        loading={loading}
                        operations={operations}
                        refresh={refresh}
                    />
                    <RestoreButton
                        field={field}
                        form={form}
                        isDeleted={isDeleted}
                        isNewCategory={isNewCategory}
                        loading={loading}
                        refresh={refresh}
                    />
                </Space>
            </Row>
        </Card>
    );
};


export const ManageDiversityCategories = () => {
    const navigate = useNavigate();
    const [editDiversityCategoriesForm] = Form.useForm<EditDiversityCategoriesForm>();
    const { loading, categories, refresh } = useAdminDiversityCategoryQueries();

    const initialValues = useMemo(() => (
        { categories: categories.map(category => ({ ...category, priority: category.priority ?? 0 })) }
    ), [categories]);

    const categoryPriorities = useMemo(() => {
        const priorities: number[] = [];
        for (let i = 0; i <= initialValues.categories.length; i ++) {
            priorities.push(i);
        }
        return priorities;
    }, [initialValues.categories]);

    useEffect(() => { 
        editDiversityCategoriesForm.resetFields();
    }, [editDiversityCategoriesForm, categories]);

    return (
        <>
            {/* Page Title */}
            <CustomHelmet title="Manage Diversity Categories" />

            {/* Page Header */}
            <PageHeader
                onBack={() => navigate("/")}
                title="Manage Diversity Categories"
                subTitle="Use this page to update diversity categories."
            />

            {/* Edit Diversity Categories Form */}
            <Form
                autoComplete="off"
                form={editDiversityCategoriesForm}
                initialValues={initialValues}
            >
                <Form.List name="categories">
                    {(fields, operations) =>
                        <>
                            <Row className="input-card-spacing">
                                <Col offset={1} span={22}>
                                    {
                                        fields.map((field) => (
                                            <DiversityCategoryInput
                                                key={field.key}
                                                categoryPriorities={categoryPriorities}
                                                field={field}
                                                form={editDiversityCategoriesForm}
                                                loading={loading}
                                                operations={operations}
                                                refresh={refresh}
                                            />
                                        ))
                                    }
                                </Col>
                            </Row>
                            <Row className="input-card-spacing">
                                <Col offset={1} span={22}>
                                    <Form.Item>
                                        <Button 
                                            block
                                            icon={<PlusOutlined />}
                                            loading={loading}
                                            onClick={() => operations.add()} 
                                            type="dashed" 
                                        >
                                            Add Diversity Category
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    }
                </Form.List>
            </Form>
        </>
    );
};