import { CloseCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Checkbox, Col, Collapse, message, Popconfirm, Row, Select, Tabs, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { mapCsvPublishedRecordSets } from "./datasetDetailsExport";
import "./DatasetDetails.css";
import { DatasetDetailsScoreCard } from "./DatasetDetailsScoreCard";
import { DatasetDetailsRecordsTable } from "./DatasetDetailsRecordsTable";
import { flattenPublishedDocumentEntries, IPublishedRecordSetDocument, PublishedRecordSet } from "./PublishedRecordSet";
import { Pie5050 } from "../../components/Charts/Pie";
import { LineColumn } from "../../components/Charts/LineColumn";
import { DataEntryTable } from "./DataEntryTable";
import CustomHelmet from "../../components/CustomHelmet";
import { IDatasetDetailsFilter, PublishedDateRange } from "../../hooks/DatasetDetailsFilterProvider";
import { Loading } from "../../components/Loading/Loading";
import { GetAllCategories, GetAllCategories_categories } from "../../graphql/__generated__/GetAllCategories";
import { GetDataset, GetDatasetVariables } from "../../graphql/__generated__/GetDataset";
import { DELETE_PUBLISHED_RECORD_SET } from "../../graphql/__mutations__/DeletePublishedRecordSet.gql";
import { GET_ALL_CATEGORIES } from "../../graphql/__queries__/GetAllCategories.gql";
import { GET_DATASET } from "../../graphql/__queries__/GetDataset.gql";
import { targetStates, filteredRecords } from "../../selectors/TargetStates";
import { flattened, grouped, IChartData } from "../../selectors/ChartData";
import { generateCsvExport } from "../../utils/csvExport";
import { sortDiversityCategories } from "../../utils/sortDiversityCategories";

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Text } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);


const EXPECTED_DATE_TIME_FORMAT = "YYYY-M-DTHH:mm:ss";

export const getCategoryAttributes = (
    categoryName: string,
    activeCategories: GetAllCategories_categories[],
): GetAllCategories_categories | undefined => activeCategories.find(category => category.name ===  categoryName);

export const FilterContext = createContext<Dispatch<SetStateAction<IDatasetDetailsFilter>> | null>(null);


export const DatasetDetails = (): JSX.Element => {
    const { t } = useTranslation();
    
    const { datasetId } = useParams() as { datasetId: string };
    
    const [exportAllPublishedRecordSetsUrl, setExportAllPublishedRecordSetsUrl] = useState<string | undefined>(undefined);
    const [activeTab, setActiveTab] = useState("input");
    const [selectedFilters, setSelectedFilters] = useState<IDatasetDetailsFilter>(
        { DateRange: null, categories: [], PublishedDateRange: PublishedDateRange.last3Periods }
    );

    const datasetResponse = useQuery<GetDataset, GetDatasetVariables>(GET_DATASET, { variables: { id: datasetId } });
    const { data: queryData, loading: queryLoading, error: queryError } = datasetResponse;

    const categoriesResponse = useQuery<GetAllCategories>(GET_ALL_CATEGORIES);
    const { data: categoriesData, loading: categoriesLoading, error: categoriesError } = categoriesResponse;

    const [deletePublishedRecordSet, { loading: deleting }] = useMutation(
        DELETE_PUBLISHED_RECORD_SET,
        { refetchQueries: [{ query: GET_DATASET, variables: { id: datasetId } }] }
    );

    const tz = useMemo(() => dayjs.tz.guess(), []);

    const parsedRecordsMemo = useMemo(() => (
        queryData ? {
            dataset: {
                ...queryData.dataset,
                publishedRecordSets: queryData.dataset.publishedRecordSets
                    ?.map(x => ({
                        ...x,
                        begin: dayjs(x.begin, EXPECTED_DATE_TIME_FORMAT, tz),
                        end: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, tz)
                    })) ?? null
            }
        } : undefined
    ), [queryData, tz]);


    const filteredRecordsMemo = useMemo(() => (
        filteredRecords(parsedRecordsMemo, selectedFilters.DateRange, tz)
    ), [selectedFilters, parsedRecordsMemo, tz]);

    const activeCategories = useMemo(() => (
        (categoriesData?.categories || []).filter((category): category is GetAllCategories_categories => category !== null)
    ), [categoriesData]);

    const categories = useMemo(() => (
        Array.from(
            new Set(
                queryData?.dataset.publishedRecordSets?.flatMap(x => (
                    flattenPublishedDocumentEntries((x.document as IPublishedRecordSetDocument).record)
                        .map(x => activeCategories.find(category => category.name === x.category))
                        .filter((x: GetAllCategories_categories | undefined): x is GetAllCategories_categories => !!x)
                ))
            )
        ).sort((a, b) => sortDiversityCategories(a.priority, b.priority))
    ), [activeCategories, queryData?.dataset.publishedRecordSets]);

    const filteredData = useMemo(() => {
        return parsedRecordsMemo?.dataset.publishedRecordSets
            ?.flat()
            .sort((a, b) => b.end.unix() - a.end.unix())
            .filter(x => {
                if (!selectedFilters.PublishedDateRange || Number(selectedFilters.PublishedDateRange) < 2000) {
                    return true;
                } else {
                    return x.end.year() == Number(selectedFilters.PublishedDateRange);
                }
            })
            .slice(0, Number(selectedFilters.PublishedDateRange))
            .flatMap(x => flattenPublishedDocumentEntries((x.document as IPublishedRecordSetDocument).record)
                .filter(x => !selectedFilters.categories.length || selectedFilters.categories.includes(x.category))
                .map((r) => ({
                    ...r, 
                    date: dayjs(x.end, EXPECTED_DATE_TIME_FORMAT, tz),
                    attribute: r.attribute === "Unknown" ? `Unknown ${r.category}` : r.attribute
                } as IChartData))
            );
    }, [parsedRecordsMemo, selectedFilters, tz]);

    const chartData = useMemo(() => {
        const processed = flattened(grouped(filteredData));
        return processed.filter(chartData => activeCategories.map(({ name }) => name).includes(chartData.category));
    }, [activeCategories, filteredData]);

    const fudgedChartData = useMemo(() => {
        const cats = Array.from(new Set(chartData.map(x => x.category)));
        return cats.map(cat => {
            const filteredByCategory = chartData.filter(x => x.category === cat);
            const monthYears = Array.from(new Set(filteredByCategory.map(x => x.groupedDate)));
            return monthYears.map(my => {
                const filteredByCategoryDate = filteredByCategory.filter(x => x.groupedDate === my);
                const sum = filteredByCategoryDate
                    .reduce((sum, x) => {
                        return sum += x.percent;
                    }, 0);
                const diff = 100.0 - sum;
                if (diff !== 0) {
                    const maxValue = Math.max(...filteredByCategoryDate.map(x => x.percent));
                    return filteredByCategoryDate.map(x => {
                        //fudges the biggest segment
                        if (x.percent === maxValue) {
                            return { ...x, percent: x.percent + diff };
                        }
                        return x;
                    });
                }

                return filteredByCategoryDate;
            }).flat();
        }).flat();
    }, [chartData]);

    useMemo(() => {
        const { mappedRows, titleKeys } = mapCsvPublishedRecordSets(queryData?.dataset);
        if (!mappedRows || !titleKeys) {
            return;
        }

        const url = generateCsvExport({ items: mappedRows, headers: titleKeys });
        setExportAllPublishedRecordSetsUrl(url);
    }, [queryData]);

    if (queryLoading || categoriesLoading) {
        return <Loading />;
    }

    if (queryError) {
        throw queryError;
    }

    if (categoriesError) {
        throw categoriesError;
    }

    const noDataAvailable = () => (
        <div style={{ marginTop: "auto", marginBottom: "auto", textAlign: "center" }}>
            <Text strong>{t("noDataAvailable")}</Text>
        </div>
    );

    return (
        <div className="dataset-details_container">
            <CustomHelmet title={queryData?.dataset?.name} subTitle="Details" />
            <Row gutter={[0, 40]}>
                <Col span={24}>
                    <PageHeader
                        title={queryData?.dataset?.program?.name}
                        subTitle={queryData?.dataset?.name}
                        extra={activeTab === "published" && [
                            // Export All Published Record Sets //
                            <Button
                                title={t("datasetDetails.tabs.published.actions.export")}
                                key="export-all-published-record-sets"
                                type="primary"
                                shape="round"
                                disabled={queryLoading || categoriesLoading || !queryData?.dataset}
                                download={`${queryData?.dataset.name || datasetId}.csv`}
                                href={exportAllPublishedRecordSetsUrl}
                                icon={<DownloadOutlined />}
                            >
                                {t("datasetDetails.tabs.published.actions.export")}
                            </Button>
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <Tabs onTabClick={(key) => setActiveTab(key)} tabBarExtraContent={activeTab !== "published"}>
                        <TabPane tab="Input" key="input">
                            <Row justify="center" gutter={[0, 50]}>
                                {
                                    targetStates(activeCategories, parsedRecordsMemo, selectedFilters.DateRange, tz)
                                        ?.map((targetState, i) => (
                                            <Col key={i} span={6}>
                                                {!isNaN(targetState.status) ? (
                                                    <Pie5050
                                                        attrsInTarget={targetState.attributesInTarget}
                                                        attrsOOTarget={targetState.attributesOOTarget}
                                                        categoryName={targetState.target.category.name}
                                                        legend={false}
                                                        inTargetColor={targetState.target.category.inTargetColor}
                                                        outTargetColor={targetState.target.category.outTargetColor}
                                                        status={targetState.status}
                                                        target={targetState.target.target * 100}
                                                    />
                                                ) : (
                                                    noDataAvailable()
                                                )}
                                                <h2 style={{ textAlign: "center" }}>{targetState.target.category.displayName}</h2>
                                            </Col>
                                        ))
                                }
                                <Col span={24}>
                                    <FilterContext.Provider value={setSelectedFilters}>
                                        <DataEntryTable id={datasetId} activeCategories={activeCategories} />
                                    </FilterContext.Provider>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="Details">
                            {
                                filteredRecordsMemo?.length ? (
                                    <Row>
                                        <Col span={24}>
                                            <DatasetDetailsScoreCard
                                                activeCategories={activeCategories}
                                                data={queryData}
                                                datasetId={datasetId}
                                                filteredRecords={filteredRecordsMemo}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <DatasetDetailsRecordsTable
                                                activeCategories={activeCategories}
                                                datasetId={datasetId}
                                                datasetData={queryData}
                                                isLoading={queryLoading || categoriesLoading}
                                                records={filteredRecordsMemo}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    noDataAvailable()
                                )
                            }
                        </TabPane>
                        <TabPane tab="Published" key="published">
                            {
                                queryData?.dataset.publishedRecordSets?.length ?
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col span={24} style={{ textAlign: "center" }}>
                                            <Checkbox.Group
                                                options={categories.map(({ name, displayName }) => ({ label: displayName, value: name }))}
                                                value={selectedFilters.categories.flat()}
                                                onChange={(e) => setSelectedFilters(curr => ({ ...curr, categories: e.map(x => x.toString()) }))}
                                            />
                                        </Col>
                                        <Col span={4} style={{ textAlign: "center" }}>
                                            <Select
                                                defaultValue={selectedFilters.PublishedDateRange}
                                                style={{ width: "100%" }}
                                                onChange={(e) => setSelectedFilters((curr) => ({ ...curr, PublishedDateRange: e }))}
                                            >
                                                {
                                                    Object.entries(PublishedDateRange)
                                                        .filter(([k,]) => isNaN(Number(k)))
                                                        .map(([k, v]) =>
                                                            <Select.Option value={v} key={k}>
                                                                {t(`datasetDetails.${PublishedDateRange[Number(v)]}`)}
                                                            </Select.Option>
                                                        )
                                                }
                                                {
                                                    Array.from(new Set(parsedRecordsMemo?.dataset.publishedRecordSets?.
                                                        map(x => x.end.year())))
                                                        .sort((a, b) => b - a)
                                                        .map(year =>
                                                            <Select.Option value={year} key={year}>
                                                                {year}
                                                            </Select.Option>
                                                        )
                                                }
                                            </Select>
                                        </Col>
                                        <Col span={24}>
                                            <LineColumn
                                                data={fudgedChartData}
                                                loading={queryLoading || categoriesLoading}
                                                columnOptions={{
                                                    isGroup: true,
                                                    groupField: "category",
                                                    isStack: true,
                                                    yAxis: {
                                                        maxLimit: 100
                                                    },
                                                    annotations: Array.from(new Set(chartData.map(x => x.category)))
                                                        .map(category => {
                                                            const target = queryData?.dataset?.program?.targets
                                                                .find(y => y.category.name.toLowerCase() === category.toLowerCase())?.target;
                                                            if (!target) return { type: "line" }; //TODO: It is possible that the target could have been deleted even though there are published records
                                                            return {
                                                                type: "line",
                                                                top: true,
                                                                start: ["-1%", 100 - (target * 100)],
                                                                end: ["101%", 100 - (target * 100)],
                                                                style: {
                                                                    lineWidth: 3,
                                                                    stroke: getCategoryAttributes(category, activeCategories)?.inTargetColor || "#000000",
                                                                },
                                                                text: {
                                                                    content: "",
                                                                    position: "start",
                                                                    offsetY: 10,
                                                                    offsetX: -35,
                                                                    style: { fontSize: 20, fontWeight: 300 },
                                                                },
                                                            };
                                                        }
                                                        )
                                                }}
                                            />
                                        </Col>
                                        <Col span={24}>
                                            <Collapse>
                                                {
                                                    queryData?.dataset.publishedRecordSets?.flat()
                                                        .sort((a, b) => Date.parse(b.end) - Date.parse(a.end))
                                                        .map(prs =>
                                                            <Panel
                                                                key={prs.reportingPeriodId}
                                                                header={
                                                                    `${
                                                                        dayjs(
                                                                            prs.begin, EXPECTED_DATE_TIME_FORMAT, tz
                                                                        ).format("D MMM YY")
                                                                    } - ${
                                                                        dayjs(
                                                                            prs.end, EXPECTED_DATE_TIME_FORMAT, tz
                                                                        ).format("D MMM YY")
                                                                    }`
                                                                }
                                                                extra={
                                                                    <Popconfirm
                                                                        title={t("confirmDelete")}
                                                                        onConfirm={async (e) => {
                                                                            e?.stopPropagation();
                                                                            if (prs.deleted !== null) {
                                                                                message.error(t("datasetDetails.unpublishDisabledText"));
                                                                                return;
                                                                            }
                                                                            await deletePublishedRecordSet({
                                                                                variables: {
                                                                                    id: prs.id
                                                                                }
                                                                            })
                                                                                .then(() => message.success(t("recordsUnpublished")))
                                                                                .catch((e) => message.error(e.message));
                                                                        }
                                                                        }
                                                                        okButtonProps={{ loading: deleting }}
                                                                        onCancel={(e) => e?.stopPropagation()}
                                                                    >
                                                                        <Tooltip 
                                                                            title={prs.deleted !== null ? t("datasetDetails.unpublishDisabledText") : ""}
                                                                        >
                                                                            <Button
                                                                                aria-label={t("datasetDetails.unpublish")}
                                                                                danger
                                                                                disabled={prs.deleted !== null}
                                                                                icon={<CloseCircleOutlined />}
                                                                                onClick={(e) => e.stopPropagation()}
                                                                                tabIndex={-1}
                                                                                title={t("datasetDetails.unpublish")}
                                                                                type="default"
                                                                            >
                                                                                {t("datasetDetails.unpublish")}
                                                                            </Button>
                                                                        </Tooltip>
                                                                    </Popconfirm>
                                                                }
                                                            >
                                                                <PublishedRecordSet
                                                                    activeCategories={activeCategories}
                                                                    publishedRecordSet={prs}
                                                                />
                                                            </Panel>
                                                        )
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    : <h3>{t("noPublishedRecordSets")}</h3>
                            }
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div >
    );
};
