import { gql } from "@apollo/client";

export const GET_ADMIN_CATEGORIES = gql`
  query GetAdminCategories {
    adminCategories {
      id
      name
      deleted
      description
      displayName
      priority
      inTargetColor
      outTargetColor
    }
  }
`;
